import { Image, Tabs } from "antd";
import React from "react";
import headerLogo from "../assets/images/purchasedSide.svg";
import newAvailableTest from "../assets/images/new_availableTest.svg";
import emptydata from "../assets/images/emptyAvailable.svg";
import newEmptyData from "../assets/images/newEmptyData.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getMyPurchasedList } from "../Slices/PurchasedListSlice";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import Loading from "../Component/Loading";
import AvailableTestComponent from "../Component/availableTestComponent";
import { useNavigate } from "react-router-dom";
import { addBreadCrumbData } from "../Slices/HomePageSlice";
import BreadCrumbDetails from "../Component/BreadCrumb";

const { TabPane } = Tabs;

const AvailableTest = () => {
  const { myPurchasedList, isPurchasedTestLoading, purchasedCount } =
    useSelector((state) => state.PurchasedList);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const totalTest = () => {
    let total = 0;
    for (let i = 0; i < purchasedCount?.length; i++) {
      total = total + +purchasedCount[i].numberOfTest;
    }
    return total;
  };

  useEffect(() => {
    dispatch(getMyPurchasedList())
      .unwrap()
      .then((res) => {});
    dispatch(addBreadCrumbData({ isAvailabe: true }));
  }, []);

  const onTabChange = (event) => {};
  const onClickTest = (data) => {
    if (data?.typeOfTestId != 4) {
      navigate(`/home/testInstruction/${data?.testId}`);
    } else {
      navigate(`/home/previousYearTestInstruction/${data?.testId}`);
    }
  };

  return (
    <>
      <div className="availableTest-container">
        <div className="breadCrumbs-container">
          <BreadCrumbDetails></BreadCrumbDetails>
        </div>
        <div className="availableTest-header">
          <h2 className="header-text">My Available Tests</h2>

          <Image preview={false} src={newAvailableTest}></Image>
        </div>
        {myPurchasedList?.length ? (
          <div className="availableTest-content">
            <div className="availableTest-filter-list">
              <Tabs onChange={(e) => onTabChange(e)}>
                <TabPane tab={`All(${totalTest()})`} key={-1}>
                  <div className="availableTest-list-data">
                    {myPurchasedList.map((item, index) => (
                      <AvailableTestComponent
                        key={index + 1}
                        data={item}
                        testPressed={onClickTest}
                      />
                    ))}
                  </div>
                </TabPane>
                {purchasedCount?.length
                  ? purchasedCount.map((item, index) => {
                      return (
                        <TabPane
                          tab={item.testType + `(${item.numberOfTest})`}
                          key={index + 2}
                        >
                          <div className="availableTest-list-data">
                            {myPurchasedList.map((listData, index) => {
                              return item.id == listData.typeOfTestId ? (
                                <AvailableTestComponent
                                  key={index}
                                  data={listData}
                                  testPressed={onClickTest}
                                />
                              ) : null;
                            })}
                          </div>
                        </TabPane>
                      );
                    })
                  : null}
              </Tabs>
            </div>
          </div>
        ) : (
          <div className="empty-container">
            <div className="empty-content">
              <Image preview={false} src={newEmptyData}></Image>
              <p className="empty-text">You haven't any Available test.</p>
            </div>
          </div>
        )}
      </div>
      {isPurchasedTestLoading ? (
        <div className="loading-container">
          <Loading></Loading>
        </div>
      ) : null}
    </>
  );
};

export default AvailableTest;
