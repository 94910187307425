import AddImage from '../assets/images/add2.svg';
import MinusImage from '../assets/images/minus.svg';
import { Image, Divider, message, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeFromCart } from '../Slices/TestListSlice'
import { getAllCart, updateCart, overAllPriceIncrease,overAllPriceDecrease } from '../Slices/TestListSlice';
import { useNavigate } from 'react-router-dom';
import '../styles/cart.scss';
import { handleToastHelper } from "../utlis/HelperFunctions.js";


function CartComponent({ item ,from}) {

  const {overAllPrice}=useSelector((state)=>state.TestList)

  const [numberOfTest, setNumberOfTest] = useState(item?.noOfTest || null);
  const [visible, setVisible] = useState(false)
  const [cartRemove, setCartRemove] = useState()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [overAllAmount, setOverAllAmount]=useState(overAllPrice);

  function removeTest(remove) {
    setVisible(true)
    setCartRemove(remove.id)
  };

  function handleOk() {
    setVisible(false)
    dispatch(removeFromCart(cartRemove)).unwrap().then((res) => {
      if (res.success == true) {
        dispatch(getAllCart())
        message.success(res.removeMessage)
      }
    })
  };

  function handleCancel() {
    setVisible(false)
  };

  async function increaseCount() {
    const newCount = numberOfTest + 1;
    try {
      const response = await dispatch(updateCart({ noOfTest: newCount, id: item?.id })).unwrap();
      setNumberOfTest(newCount);
      handleToastHelper(response);
      dispatch(overAllPriceIncrease(item?.testPrice));
    } catch (error) {
      handleToastHelper(error);
    }
  };
  
  async function decreaseCount() {
    const newCount = numberOfTest - 1;
    try {
      const response = await dispatch(updateCart({ noOfTest: newCount, id: item?.id })).unwrap();
      setNumberOfTest(newCount);
      handleToastHelper(response);
      dispatch(overAllPriceDecrease(item?.testPrice));
    } catch (error) {
      handleToastHelper(error);
    }
  };  

  {
    let { typeOfTestId = '', testName = '', isModuleSubscription = null, testPrice = '' , price = '', typeOfTest = '', examName = '', name ='', subscriptionType = '', period } = item;

     return (
      <div className='over-all-cart-item'>
        <div className='cart-item'>
           <div className="cart-item-content">
             {/* <p className="sno">{i+1} .</p> */}
             <div className="content">

               {!isModuleSubscription ?
                 (
                   <>
                     <p className={typeOfTestId == 1 ? 'full-test-title' : (typeOfTestId == 2 ? 'subject-title' : 'chapter-title')}><span> {typeOfTest}</span><span className='examName'> {examName}</span></p>
                     <p className="test-title"> {testName}</p>
                   </>
                 ) :
                 (
                   <>
                     <p className='subscription-text'>
                      <span>Subscription</span>
                      { period && (
                        <span> ( {period} )</span>
                      )}
                     </p>
                     <p className="test-title">
                      <span>{subscriptionType}</span>
                      <span></span>
                      <span>{name}</span>
                      </p>
                   </>
                 )
               }
             </div>
           </div>

           {
             !isModuleSubscription &&
             (
               <div className="count">
                 {
                   from == "cart" ?
                     <>
                       <Image preview={false} src={MinusImage} onClick={(numberOfTest > 1) ? decreaseCount : null}></Image>
                       <p>{numberOfTest}</p>
                       <Image preview={false} src={AddImage} onClick={increaseCount}></Image>
                     </>
                     :
                     <p>{numberOfTest}</p>
                 }
               </div>
             )
           }

          <div className="cart-item-price">
            <div className="price"><p>₹  { testPrice || price }</p></div>
          </div>
        </div>
        <Divider style={{background:'#E1E7EA'}}></Divider>
        <div className="single-cart-footer">
          {
            from == "cart" &&
              <a className="remove-cta" onClick={() => removeTest(item)}>Remove</a>
          }
           {
             !isModuleSubscription ? (
               <p className="sub-total">Subtotal ({numberOfTest}) <span>₹ {numberOfTest * testPrice}</span></p>
             ) : (
               <p className="sub-total">Total <span> ₹ {price}</span></p>
             )
           }
        </div>
        {/* reomve from cart modal */}
        <Modal title="Remove from cart" visible={visible} onOk={handleOk} onCancel={handleCancel}>
          <p>Are you sure want to remove cart ?</p>
        </Modal>
      </div>
      )
  }
}
export default CartComponent;