import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetUserDetails } from "../Slices/HomePageSlice";
import { Input, Select, Image, Button } from "antd";
import { Option } from "antd/lib/mentions";
import searchIcon from "../assets/images/Search_light.svg";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import {
  getActiveCluster,
  getSubscriptionPlan,
  getAllClusterGroupByClusterId,
  getAllCourseByClusterId,
  getFreeClassesByClusterId,
  getAllTestSeriesByClusterId,
  getAllOtherVideosByClusterId,
  getFreeClass,
  getEducatorbyClusterId,
  educatorFollowStatus,
  getCoachmiBanner,
  getContentVideoUrl,
  removesubscription
} from "../Slices/CoachmiSlice";
import "../styles/coachmi.scss";
import "antd/dist/antd.css";
import BannerImage from "../assets/images/Rectangle 3463939.svg";
import RocketImage from "../assets/images/rocket.svg";
import RightArrow from "../assets/images/category_right_icon.svg";
import FreeClassIcon from "../assets/images/freeclasses.svg";
import CommunityIcon from "../assets/images/community.svg";
import CoachmiIcon from "../assets/images/coachmi.svg";
import TestmiIcon from "../assets/images/testmi.svg";
import EducatorsIcon from "../assets/images/educators.svg";
import CoursesIcon from "../assets/images/courses.svg";
import ReferFriendImage from "../assets/images/referFriend.svg";
import CommunityMembersIcon from "../assets/images/community-members.svg";
import KeyIcon from "../assets/images/key 1.svg";
import NotificationIcon from "../assets/images/notification 1.svg";
import NotebookIcon from "../assets/images/notebook 1.svg";
import ExamIcon from "../assets/images/exam 1.svg";
import PdfIcon from "../assets/images/pdf 1.svg";
import NewsIcon from "../assets/images/news 1.svg";
import Community from "../assets/images/communityIcon.svg";
import { ReactComponent as TamilIcon } from "../assets/images/tamilIcon.svg";
import { ReactComponent as EnglishIcon } from "../assets/images/englishIcon.svg";
import { ECoachmiSubscription, EContentType } from "../utlis/enum.js";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import UserIcon from '../assets/images/user_icon.svg'
import { Tooltip } from 'antd';
import { getLeastMonthsSubscriptionHelper, setLocalStorageItemHelper, renderFollowStatusFunction, getLocalStorageItemHelper, handleToastHelper, CoursePricingHelper, renderVerticalLineHelper } from '../utlis/HelperFunctions.js';
import Loading from '../Component/Loading.js';
import SearchBar from "../Component/SearchBar.js";
import ShareAppLink from "../Component/ShareAppLink";

const Coachmi = () => {
  const [profileImage, setProfileImage] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { getUserDetails } = useSelector((state) => state.HomePage);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {
    clusterData,
    subscriptionData,
    clusterGroupData,
    allCourseData,
    testData,
    otherVideosData,
    freeClassData,
    educatorClusterData,
    bannerData,
    isLoadingCluster,
    isEducatorLoading,
    isLoading
  } = useSelector((state) => state.Coachmi);

  // const [profileLoading, setProfileLoading] = useState(true);
  // const [educators, setEducators] = useState([]);

  useEffect(() => {
    getAllDetails();
    dispatch(getActiveCluster())
  }, []);

  const [clusterId, setClusterId] = useState(getLocalStorageItemHelper('selectedCluster'));
  const [searchQuery, setSearchQuery] = useState("");
  const inputRef = useRef(null);
  const [videoUrls, setVideoUrls] = useState({});
  const videoRefs = useRef({});

  useEffect(() => {
    if (clusterId) {
      dispatch(removesubscription());
      dispatch(getSubscriptionPlan({ type: ECoachmiSubscription.CLUSTER, id: clusterId, queryParams: { isLeastPrice: true } }));
      dispatch(getAllClusterGroupByClusterId({ clusterId: clusterId, isLimitedData: true }));
      dispatch(getEducatorbyClusterId({ clusterId: clusterId, isLimitedData: true }))
      dispatch(getAllCourseByClusterId({ clusterId: clusterId, isLimitedData: true }));
      dispatch(getFreeClassesByClusterId({ clusterId: clusterId, isLimitedData: true }));
      dispatch(getAllTestSeriesByClusterId({ clusterId: clusterId, isLimitedData: true }));
      dispatch(getAllOtherVideosByClusterId({ clusterId: clusterId, isLimitedData: true }));
      dispatch(getCoachmiBanner());
    }
  }, [clusterId, dispatch]);

  useEffect(() => {
    if (clusterData) {
      const storedClusterId = getLocalStorageItemHelper('selectedCluster');
      let tempClusterId = storedClusterId ? JSON.parse(storedClusterId) : clusterData?.[0]?.['id'];
      setLocalStorageItemHelper('selectedCluster', JSON.stringify(tempClusterId));
      tempClusterId = Number(tempClusterId);
      setClusterId(tempClusterId);
    }
  }, [clusterData]);


  const getAllDetails = () => {
    dispatch(GetUserDetails()).then((res) => {
      localStorage.setItem("name", res.payload.name);
      let name = res.payload.name.trim().charAt(0).toUpperCase();
      // setProfileName(name);
      setProfileImage(res.payload.key);
      // setProfileLoading(false);
    });
  };

  const handleOnChange = (e) => {
    setSearchQuery(e.target.value)
  };

  const findSearchResults = (e) => {
    e.preventDefault();
    inputRef.current.blur();
  };

  const debounced = useDebouncedCallback((obj) => {
    // dispatch(GetAllExam(obj))
    //    dispatch(GetExamGroupBySlugId(obj))
  }, 400);

  const onChange = (value) => {
    setClusterId(value);
    setLocalStorageItemHelper('selectedCluster', JSON.stringify(value));
  };

  const subscribeCoachmi = () => { };

  const cardData = [
    {
      title: "Testmi",
      description: "Evaluate and boost your exam preparation with test series.",
      icon: TestmiIcon,
    },
    {
      title: "Educators",
      description: "Evaluate and boost your exam preparation with test series.",
      icon: EducatorsIcon,
    },
    {
      title: "Free Classes",
      description: "Evaluate and boost your exam preparation with test series.",
      icon: FreeClassIcon,
    },
    {
      title: "Courses",
      description: "Evaluate and boost your exam preparation with test series.",
      icon: CoursesIcon,
    },
    {
      title: "Community",
      description: "Evaluate and boost your exam preparation with test series.",
      icon: CommunityIcon,
    },
    {
      title: "Coachmi",
      description: "Evaluate and boost your exam preparation with test series.",
      icon: CoachmiIcon,
    },
  ];


  const leastNumberOfMonthsSubscription = getLeastMonthsSubscriptionHelper(subscriptionData?.planPricing);

  const navigateToAllCourse = () => {
    navigate(`/home/coachmi/allCourse/${clusterId}`);
  };

  const navigateToAllClusterGroup = () => {
    navigate(`/home/allClusterGroups/${clusterId}`);
  };

  const navigateToAllFreeClass = () => {
    navigate(`/home/allFreeClass/${clusterId}`);
  };

  const navigateToAllOtherVideos = () => {
    navigate(`/home/allOtherVideos/${clusterId}`);
  };

  const navigateToTestSeries = () => {
    navigate(`/home/coachmiTestSeries/${clusterId}`);
  }


  const handleSubscribeClick = () => {
    setLocalStorageItemHelper('subscriptionGoBackPath', location.pathname)
    navigate(`/home/subscriptionPlan/${clusterId}/?type=${ECoachmiSubscription.CLUSTER}`);
  };

  const navigateToAllEducators = () => {
    navigate(`/home/allEducators/${clusterId}`)
  };

  const handleFollow = (id) => {
    dispatch(educatorFollowStatus({ id, clusterId, isLimitedData: true }));
  };

  const takeTest = (testId) => {
    navigate(`/home/previousYearTestInstruction/${testId}`)
  }

  const onClickCommunity = () => {
    window.open('https://centum.academy/community', '_blank');
  };


  const getEmbedUrl = (url) => {
    const videoIdMatch = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|[^\/\n\s]+\/(?:[^\n\s]+\/)?|watch\?v=)|youtu\.be\/)([^&?/\n\s]+)/);
    const videoId = videoIdMatch ? videoIdMatch[1] : null;
    return videoId ? `https://www.youtube.com/embed/${videoId}` : null;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  const handleClusterChange = (value) => {
    setClusterId(value);
    setLocalStorageItemHelper('selectedCluster', JSON.stringify(value));
  };

  const getCloudFrontUrl = async (id, isOtherVideo = false) => {
    try {

      let key = isOtherVideo ? `${id + 'otherVideo'}` : `${id + 'contentVideo'}`;
      const response = await dispatch(getContentVideoUrl({ contentAttachmentId: id, type: isOtherVideo ? EContentType.OTHER_VIDEO_CONTENT : EContentType.COACHMI_CONTENT })).unwrap();
      const videoUrl = response['data'] || {};

      setVideoUrls((prevUrls) => ({
        ...prevUrls,
        [key]: videoUrl,
      }));

      if (videoRefs.current[key]) {
        videoRefs.current[key].load();
        videoRefs.current[key].play();
      }
    } catch (error) {
      handleToastHelper(error);
    }
  };

  return (
    <div className="coachmi-container">
      {
        isLoadingCluster == true ? (
          <Loading></Loading>
        ) : (<div className="coachmi-header">
          <div className="user-container">
            <div className="userProfile">
              <Image src={profileImage ? profileImage : UserIcon} preview={false} className="profile" />
              <div className="user-details">
                <p className="user-name">{getUserDetails?.name} </p>
                <p className="tag">Hope test was great today!</p>
              </div>
            </div>
          </div >
          <div className="search">
            <SearchBar clusterId={clusterId} />
          </div>
          {
            isLoading ? (
              <div className='loading-container'>
                <Loading></Loading>
              </div>
            ) : (
              <div className="cluster-dropdown">
                <Select
                  id="clusterId"
                  name="clusterId"
                  variant="filled"
                  placeholder="Cluster"
                  value={clusterId}
                  style={{ flex: 1, width: "100%" }}
                  onChange={handleClusterChange}
                >
                  {clusterData?.map((data) => (
                    <Option key={data.id} value={data.id}>
                      <Tooltip title={data.name}>
                        <span className="capitalize">{data.name}</span>
                      </Tooltip>
                    </Option>
                  ))}
                </Select>
              </div>
            )
          }
        </div >
        )
      }
      <div className="coachmi-content-section">
        <div className="banner-image">
          <Slider {...settings}>
            {bannerData?.map((banner, index) => (
              <Image preview={false} key={index} src={banner.bannerUrl}></Image>
            ))}
          </Slider>
        </div>
      </div>
      <div className="coachmi-features">
        <div className="container">
          {cardData.map((card, index) => (
            <div className="card" key={index}>
              <div className="icon">
                <img src={card.icon} alt={card.title} />
              </div>
              <div className="card-content">
                <Tooltip title={card.title}>
                  <p className="card-title">{card.title}</p>
                </Tooltip>
                <Tooltip title={card.description}>
                  <p className="card-description">{card.description}</p>
                </Tooltip>
              </div>
              <div className="right-arrow">
                <Image preview={false} src={RightArrow}></Image>
              </div>
            </div>
          ))}
        </div>
      </div>
      {
        (subscriptionData['planPricing']?.length > 0 && !subscriptionData['isValidSubscription']) && (
          <div className="subscription-container">
            <Image preview={false} src={RocketImage} />
            <div className="subscription">
              <div className="subscription-text">
                <p className="clusterName">
                  Subscribe to Enjoy {subscriptionData?.name + ' '}
                  Unlimited Learning
                </p>
                <p className="clusterPrice">
                  Plans start at ₹
                  {leastNumberOfMonthsSubscription?.price}
                </p>
              </div>
              <div className="subscription-btn">
                <Button
                  type="default"
                  className="primary-submit-button coachm-btn"
                  onClick={() => handleSubscribeClick()}
                >
                  Subscribe
                </Button>
              </div>
            </div>
          </div>
        )
      }
      <div className="exam-courses-container">
        <div className="cluster-group-header">
          <p>Exam wise Courses</p>
          <a className="see-all" onClick={() => navigateToAllClusterGroup()}>
            See All
          </a>
        </div>
        <div className="courses">
          {clusterGroupData.map((clusterGroup, index) => (
            <div
              className="course-card"
              key={index}
              onClick={() => {
                navigate(
                  `/home/coachmi/clusterGroupCourse/${clusterGroup?.clusterGroupMappingId}`,
                  { state: { slugId: clusterGroup?.slugId } }
                );
              }}
            >
              {
                clusterGroup?.clusterGroupImage === null ? <div className="empty-image-icon"></div> : <Image
                  preview={false}
                  src={clusterGroup?.clusterGroupImage}
                  alt={clusterGroup?.clusterGroupName}
                  className="course-icon"
                />
              }
              <Tooltip placement="topLeft" title={clusterGroup?.clusterGroupName}>
                <p className="clusterGroupName">
                  {clusterGroup?.clusterGroupName}
                </p>
              </Tooltip>
            </div>
          ))}
        </div>
      </div>
      <div className="courses-container">
        <div className="courses-header">
          <p>Courses</p>
          <a className="see-all" onClick={() => navigateToAllCourse()}>
            See All
          </a>
        </div>
        <div className="courses-grid">
          {allCourseData.map((course, index) => {
            const hasDuration = Boolean(course['duration']);
            const hasTests = course['testCount'] > 0;
            const hasDocuments = course['documentCount'] > 0;

            return (
              <div
                className="course-card"
                key={index}
                onClick={() =>
                  navigate(`/home/course/${course?.courseMappingId}`)
                }
              >
                {
                  course.courseThumbnailUrl ? (
                    <img
                      src={course.courseThumbnailUrl}
                      alt={course.courseName}
                      className="course-image"
                    />
                  ) : (
                    <div className="course-image course-image-default"></div>
                  )
                }
                <div className="course-tag">
                  <p className="course-type">{course.videoType}</p>
                  {course.languageId === 1 ? (
                    <p>
                      <TamilIcon />
                    </p>
                  ) : (
                    <p>
                      <EnglishIcon />
                    </p>
                  )}
                </div>
                <Tooltip title={course.clusterGroupName}>
                  <p className="course-clusterGroup">{course.clusterGroupName}</p>
                </Tooltip>
                <Tooltip title={course.courseName}>
                  <p className="course-title">{course.courseName}</p>
                </Tooltip>
                <div className="course-details">

                  {hasDuration && (
                    <>
                      <p className="courseHours">{course['duration']}</p>
                      {renderVerticalLineHelper(hasTests || hasDocuments)}
                    </>
                  )}

                  {hasTests && (
                    <>
                      <p className="courseTests">{course['testCount']} Test Series</p>
                      {renderVerticalLineHelper(hasDocuments)}
                    </>
                  )}

                  {hasDocuments && (
                    <p className="courseDocs">{course['documentCount']} Documents</p>
                  )}

                </div>
                {CoursePricingHelper(course)}
              </div>
            )
          }
          )}
        </div>
      </div>
      <div className="refer-friend-container">
        <div className="refer-friend-content">
          <div className="refer-friend-text">
            <p className="refer-text-1">
              Refer a friend to win discount Coupons and plus Subscriptions
            </p>
            <p className="refer-text-2">
              For every successful referral win discount Coupons, Also get 1
              month plus subscription once.
            </p>
            <Button
              type="default"
              className="refer-friend-btn"
              onClick={showModal}
            >
              Refer a friend
            </Button>
          </div>
          <div className="refer-friend-image">
            <img src={ReferFriendImage} alt="Refer a friend" />
          </div>
        </div>
        <ShareAppLink
          visible={isModalVisible}
          onCancel={handleCancel}
        />
      </div>
      <div className="free-classes-container">
        <div className="header">
          <h2>Try Our Free Classes</h2>
          <a
            href="#"
            className="see-all"
            onClick={() => navigateToAllFreeClass()}
          >
            See All
          </a>
        </div>
        <div className="classes">
          {freeClassData.map((classItem, index) => (
            <div className="class-card" key={index}>
              <div className="content-video-wrapper" onClick={() => getCloudFrontUrl(classItem['attachmentMappingId'])}>
                <video width="100%" height="180" controls
                  ref={(el) => (videoRefs.current[classItem['attachmentMappingId'] + 'contentVideo'] = el)}
                >
                  <source src={videoUrls[classItem['attachmentMappingId'] + 'contentVideo']} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="class-info">
                <div className="class-text">
                  <Tooltip placement="topLeft" title={classItem.freeClassName}>
                    <p className="class-title">
                      {classItem.freeClassName}
                    </p>
                  </Tooltip>
                  <Tooltip placement="topLeft" title={classItem.educatorNames}>
                    <p className="class-instructor">By - {classItem.educatorNames}</p>
                  </Tooltip>
                </div>
                <p className="class-type">Free Class</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="educators-container">
        <div className="header">
          <h2>Educators</h2>
          <a
            href="#"
            className="see-all"
            onClick={() => navigateToAllEducators()}
          >
            See All
          </a>
        </div>
        <div className="educators">
          {educatorClusterData.map((educator, index) => (
            <div className="educator-card" key={index}>
              <img
                src={educator.attachmentUrl}
                alt={educator.educatorName}
                className="educator-image"
              />
              <div className="educator-text">
                <span className="educator-name">{educator.educatorName}</span>
                <span className="educator-follow">
                  {educator.followingCount} followers
                </span>
                {renderFollowStatusFunction(educator, handleFollow, isEducatorLoading)}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="community-card">
        <div className="community-content">
          <div className="community-header">
            <p className="heading">Join Our Community for Daily Updates</p>
            <p className="active-members">
              <img src={CommunityMembersIcon} alt=""></img> 900+ Active
              community members
            </p>
          </div>
          <Button className="join-button" onClick={onClickCommunity}>Join Community</Button>
          <div className="community-features">
            <div className="feature-item">
              <img src={KeyIcon} alt="" className="community-icon"></img> Testmi
              & Coachmi access
            </div>
            <div className="feature-item">
              <img
                src={NotificationIcon}
                alt=""
                className="community-icon"
              ></img>
              Job notifications
            </div>
            <div className="feature-item">
              <img src={NotebookIcon} alt="" className="community-icon"></img>
              Exam planner
            </div>
            <div className="feature-item">
              <img src={ExamIcon} alt="" className="community-icon"></img> Daily
              MCQs / Free quizzes
            </div>
            <div className="feature-item">
              <img src={PdfIcon} alt="" className="community-icon"></img> Study
              PDFs
            </div>
            <div className="feature-item">
              <img src={NewsIcon} alt="" className="community-icon"></img>
              Current affairs
            </div>
          </div>
        </div>
        <Image preview={false} src={Community} alt="Community" />
      </div>
      {/* <Image preview={false} src={Community} alt="Community" />
        </div> */}
      <div className="test-series">
        <div className="header">
          <h2>Test Series</h2>
          <a
            href="#"
            className="see-all"
            onClick={() => navigateToTestSeries()}
          >
            See All
          </a>
        </div>
        <div className="test-cards">
          {testData.map((test, index) => (
            <div key={index} className="test-card">
              <div className="test-info">
                <div className="test-type">
                  {test.testType} <span className="divider">/</span>
                  <Tooltip title={test.examGroupName}>
                    <span className="test-category"> {test.examGroupName}</span>
                  </Tooltip>
                </div>
                <Tooltip title={test.testName}>
                  <div className="test-title">{test.testName}</div>
                </Tooltip>
                <div className="test-details">
                  <span>{test.numberOfQuestions} Qs.</span>
                  <span>| {test.totalMarks} Marks</span>
                  <span>| {test.timeLimit} mins</span>
                </div>
                <Button className="take-test-button" onClick={() => takeTest(test?.testId)}>Take Test</Button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="free-classes-container">
        <div className="header">
          <h2>Other Videos</h2>
          <a
            href="#"
            className="see-all"
            onClick={() => navigateToAllOtherVideos()}
          >
            See All
          </a>
        </div>
        <div className="classes">
          {otherVideosData.map((classItem, index) => (
            <div className="class-card" key={index}>
              <div className="content-video-wrapper" onClick={() => getCloudFrontUrl(classItem['attachmentMappingId'], true)}>
                <video width="100%" height="180" controls
                  ref={(el) => (videoRefs.current[classItem['attachmentMappingId'] + 'otherVideo'] = el)}
                >
                  <source src={videoUrls[classItem['attachmentMappingId'] + 'otherVideo']} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="class-info">
                <div className="class-text">
                  <Tooltip placement="topLeft" title={classItem.contentName}>
                    <p className="class-title">
                      {classItem.contentName}
                    </p>
                  </Tooltip>
                  <Tooltip placement="topLeft" title={classItem.educators.map(e => e.name).join(', ')}>
                    <p className="class-instructor">
                      By - {classItem.educators.map(e => e.name).join(', ')}
                    </p>
                  </Tooltip>
                </div>
                <p className="class-type">Free Class</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div >
  );
};

export default Coachmi;
