import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getAllOtherVideosByClusterId, getContentVideoUrl } from '../Slices/CoachmiSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as TamilIcon } from '../assets/images/tamilIcon.svg';
import { ReactComponent as EnglishIcon } from '../assets/images/englishIcon.svg';
import '../styles/AllOtherVideos.scss';
import { Image } from "antd";
import BackIcon from '../assets/images/back_icon.svg';
import { Tooltip } from 'antd';
import Loading from '../Component/Loading';
import { handleToastHelper } from '../utlis/HelperFunctions';
import { EContentType } from '../utlis/enum';

const AllOtherVideos = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { otherVideosData, isLoading } = useSelector((state) => state.Coachmi);
    const [videoUrls, setVideoUrls] = useState({});
    const videoRefs = useRef({});

    useEffect(() => {
        dispatch(getAllOtherVideosByClusterId({ clusterId: params.clusterId, isLimitedData: false }))
    }, [])

    const goBack = () => {
        navigate('/home/coachmi')
    };

    const getCloudFrontUrl = async (id) => {
        try {
            const response = await dispatch(getContentVideoUrl({ contentAttachmentId: id, type: EContentType.OTHER_VIDEO_CONTENT })).unwrap();
            const videoUrl = response['data'] || {};

            setVideoUrls((prevUrls) => ({
                ...prevUrls,
                [id]: videoUrl,
            }));

            if (videoRefs.current[id]) {
                videoRefs.current[id].load();
                videoRefs.current[id].play();
            }
        } catch (error) {
            handleToastHelper(error);
        }
    };

    return (
        <>
            {
                isLoading ? (
                    <div className='loading-container'>
                        <Loading></Loading>
                    </div>
                ) : (
                    <div className="overAll-course-contaniner">
                        <div className='course-header'>
                            <Image preview={false} src={BackIcon} className="back-icon" onClick={() => goBack()} />
                            <p>Other Videos</p>
                        </div>

                        <div className="free-classes-container">
                            <div className="classes">
                                {otherVideosData.map((classItem, index) => (
                                    <div className="class-card" key={index}>
                                        <div className="content-video-wrapper" onClick={() => getCloudFrontUrl(classItem['attachmentMappingId'])}>
                                            <video width="100%" height="180" controls
                                                ref={(el) => (videoRefs.current[classItem['attachmentMappingId']] = el)}
                                            >
                                                <source src={videoUrls[classItem['attachmentMappingId']]} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                        <div className="class-info">
                                            <div className="class-text">
                                                <Tooltip placement="topLeft" title={classItem.contentName}>
                                                    <p className="class-title">
                                                        {classItem.contentName}
                                                    </p>
                                                </Tooltip>
                                                <Tooltip placement="topLeft" title={classItem.educators.map(e => e.name).join(', ')}>
                                                    <p className="class-instructor">
                                                        By - {classItem.educators.map(e => e.name).join(', ')}
                                                    </p>
                                                </Tooltip>
                                            </div>
                                            <p className="class-type">Free Class</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )
            }

        </>
    );
}

export default AllOtherVideos;
