import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Tabs, Button, Divider, Image, message, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { clientAddToCart } from '../Slices/TestListSlice';


const QuestionBankTestComponent = () => {


    const { testList, categoryListData } = useSelector((state) => state.TestList)
    const [shareTest, setShareTest] = useState();
    const [dataList, setDataList] = useState([]);
    const [categoryName, setCategoryName] = useState('');


    const dispatch = useDispatch()
    const params = useParams();


    useEffect(() => {
        let data = testList.filter((e) => e.categoryId != null && e.categoryId == params.categoryId)
        let categoryNameValue = categoryListData.filter((e) => e.id == params.categoryId)
        setDataList(data)
        setCategoryName(categoryNameValue[0]?.categoryName)
    }, [])

    const navigate = useNavigate()

    const takeTest = (testId, typeOfTest) => {
        if (typeOfTest != 4) {
            navigate(`/home/testInstruction/${testId}`)
        } else {
            navigate(`/home/previousYearTestInstruction/${testId}`)
        }
    }

    const addToCartApi = (listData) => {
        dispatch(clientAddToCart(listData)).unwrap().then((res) => {
            if (res.success == true) {
                message.success(res.message)
            }
        })
    }

    // const currentURL = window.location.href
    const currentURL = 'https://app.centum.academy/home';
    const prodUrl = ''
    const testLink = '/testInstruction/' + shareTest?.testId
    const testLinkSingleTest = '/previousYearTestInstruction/' + shareTest?.testId
    const encodedURL = encodeURIComponent(currentURL);

    async function copyToClipboard(testId, typeOfTest) {
        try {
            if (typeOfTest != 4) {
                await navigator.clipboard.writeText(currentURL + testLink);
            } else {
                await navigator.clipboard.writeText(currentURL + testLinkSingleTest);
            }
            alert('Link copied to clipboard!');
        } catch (error) {
            console.error('Failed to copy link: ', error);
        }
    };

    const shareWhatsApp = () => {
        const whatsappURL = `https://wa.me/?text=${encodedURL + testLink}`;
        window.open(whatsappURL, '_blank');
    };

    const shareFacebook = () => {
        const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${encodedURL + testLink}`;
        window.open(facebookURL, '_blank');
    };

    const shareTwitter = () => {
        const twitterURL = `https://twitter.com/intent/tweet?url=${encodedURL + testLink}`;
        window.open(twitterURL, '_blank');
    };

    const shareLinkedIn = () => {
        const linkedinURL = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedURL + testLink}`;
        window.open(linkedinURL, '_blank');
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = (data) => {
        setShareTest(data)
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div className='question-test-component'>
            <div className='category-heading'>
                <p className='category-name'>{categoryName}</p>
            </div>
            {
                dataList.length ? <div>
                    {
                        dataList?.length && dataList.map((listData, i) => {
                            return <div key={i} className='take-test-component'>
                                <div className='take-test-content'>
                                    <p className="exam-title"><span className={listData.typeOfTestId == 1 ? 'full-test-title' : (listData.typeOfTestId == 2 ? 'subject-title' : 'chapter-title')}>
                                        {listData.typeOfTestId == 1 ? 'Full Syllabus' : (listData.typeOfTestId == 2 ? 'Subject Test' : (listData.typeOfTestId == 3 ? 'Chapter Test' : 'Test Batches'))}
                                    </span>
                                        <Divider style={{ background: '#7A8B94' }} type='vertical'></Divider>
                                        <span>{listData.examName}</span></p>
                                    <p className='take-test-component-test'>{listData?.testName}</p>
                                    <div className='take-test-component-content'>
                                        <p className='take-test-component-questions'>{listData?.numberOfQuestions} questions</p>
                                        <p className='take-test-component-marks'>{listData?.totalMark} marks</p>
                                        <p className='take-test-component-time'>{listData?.timeLimit} minutes</p>
                                    </div>
                                </div>
                                <div className='add-to-cart'>
                                    {
                                        listData?.pricingPlan == 1 ?
                                            <>
                                                <div>
                                                    <p className='test-total-price'>₹{listData.price}</p>
                                                    <Button onClick={() => addToCartApi(listData)}>Add to Cart</Button>
                                                </div>
                                            </>
                                            :
                                            <div className='taketest-share-link'>
                                                <i className='shareLinkIcon' onClick={() => showModal(listData)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill='#CCCCCC' d="M307 34.8c-11.5 5.1-19 16.6-19 29.2v64H176C78.8 128 0 206.8 0 304C0 417.3 81.5 467.9 100.2 478.1c2.5 1.4 5.3 1.9 8.1 1.9c10.9 0 19.7-8.9 19.7-19.7c0-7.5-4.3-14.4-9.8-19.5C108.8 431.9 96 414.4 96 384c0-53 43-96 96-96h96v64c0 12.6 7.4 24.1 19 29.2s25 3 34.4-5.4l160-144c6.7-6.1 10.6-14.7 10.6-23.8s-3.8-17.7-10.6-23.8l-160-144c-9.4-8.5-22.9-10.6-34.4-5.4z" /></svg></i>
                                                <Button onClick={() => takeTest(listData?.testId, listData?.typeOfTestId)}>Take Test</Button>
                                            </div>
                                    }
                                </div>
                                <Modal title="Share" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
                                    <strong>Share</strong>
                                    <div className='shareIcons'>
                                        {/* <i className='icon' onClick={() => copyToClipboard(listData?.testId, listData?.typeOfTestId)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill='#007BFF' d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z" /></svg>Copy Link</i> */}
                                        <i className='icon' onClick={shareWhatsApp}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill='#25D366' d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" /></svg>Whatsapp</i>
                                        <i className='icon' onClick={shareFacebook}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill='#1877F2' d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" /></svg>Facebook</i>
                                        <i className='icon' onClick={shareTwitter}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill='#1DA1F2' d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" /></svg>Twitter</i>
                                        <i className='icon' onClick={shareLinkedIn}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill='#0A66C2' d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" /></svg>LinkedIn</i>
                                    </div>
                                </Modal>
                            </div>
                        })
                    }
                </div> : <div><p>No Data</p></div>
            }
        </div>
    )
}

export default QuestionBankTestComponent