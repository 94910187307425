import { Button } from 'antd'
import React from 'react'

const TestSeries = ({data ,seriesPress}) => {
  
  return (
     <div className='test-series-card' key={data?.id}>
      <p className='test-series-header'>TEST SERIES</p>

      <p className='test-name'>{data?.name}</p>
      <div className='test-details'>
      <span className='test-content'>
      <span className='total-test'>{data?.numberOfTestCount == null ? 0 : data?.numberOfTestCount } tests</span>
       <span  className='total-topic '>{data?.numberOfQuestions == null  ? 0 : data?.numberOfQuestions } Ques</span>
       <span  className='total-hours'>{ Math.ceil(data?.timeLimit == null ? 0 : data?.timeLimit / 60)} hrs</span>
      </span>

       <Button type='primary' className='test-series-button' onClick={()=>seriesPress(data.id)} >View Series</Button>
      </div>
     </div>
    // </div>
  )
}

export default TestSeries