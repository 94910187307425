import React, { useState, useRef, useEffect } from 'react';
import { Input, List, Empty } from 'antd'; // Make sure you have antd installed
import searchIcon from "../assets/images/Search_light.svg";
import { useDispatch } from 'react-redux';
import { getSearchValue } from "../Slices/CoachmiSlice";
import { Link } from 'react-router-dom';
import { EGobalSearchType } from '../utlis/enum';

const SearchBar = ({ clusterId }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [transformedSuggestions, setTransformedSuggestions] = useState([]);
  const inputRef = useRef(null);
  const dispatch = useDispatch()

  const handleOnChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const fetchSuggestions = async (query) => {
    let payload = {
      clusterId : clusterId,
      searchValue : query
    }
    dispatch(getSearchValue(payload)).unwrap().then(res => {
      setSuggestions(res?.data); 
      console.log(suggestions,'suggestions...');
    })
   
  };

  useEffect(() => {
    if (suggestions) {
      const groupedAndTransformed = Object.entries(
        suggestions.reduce((acc, item) => {
          let routePath = '';

          if (!acc[item.type]) {
            acc[item.type] = [];
          };

          switch(item['categoryType']){
            case EGobalSearchType.CLUSTER_GROUP:
              routePath = `clusterGroupCourse/${item['mappingId']}`
            break;
            case EGobalSearchType.COURSE:
              routePath = `/home/course/${item['mappingId']}`
            break;
            case EGobalSearchType.TOPIC:
              routePath = `/home/contentList/${item['mappingId']}`
            break;
            case EGobalSearchType.SUB_TOPIC:
              routePath = `/home/contentList/${item['mappingId']}`
            break;
            case EGobalSearchType.CLASS:
              routePath = `/home/contentList/${item['mappingId']}`
            break;
            case EGobalSearchType.CONTENT:
              routePath = `/home/content/${item['mappingId']}`
            break;

          };

          acc[item.type].push({...item, routePath});
          return acc;
        }, {})
      ).flatMap(([type, items]) => [
        { type, isHeader: true },
        ...items.map(item => ({ ...item, type }))
      ]);
      setTransformedSuggestions(groupedAndTransformed);
    }
  }, [suggestions]);


  useEffect(() => {
    if (searchQuery.length > 0) {
      fetchSuggestions(searchQuery);
    } else {
      setSuggestions([]);
    }
  }, [searchQuery]);

  return (
    <>
      <Input
        size="large"
        placeholder="Search"
        autoComplete="off"
        onChange={handleOnChange}
        value={searchQuery}
        prefix={<img src={searchIcon} alt="Search icon" />}
        ref={inputRef}
      />
      {searchQuery && transformedSuggestions?.length > 0 ? (
        <List
          itemLayout="horizontal"
          dataSource={transformedSuggestions}
          renderItem={(item) =>
            item.isHeader ? (
              <List.Item>
                <p className="data-type">{item.type}</p>
              </List.Item>
            ) : (
              <Link to={item?.routePath}>
              <List.Item>
                <List.Item.Meta title={item.name} />
              </List.Item>
              </Link>
            )
          }
        />
      ) : searchQuery ? (
        <List itemLayout="horizontal">
          <List.Item className="global-search-no-data-found">
            <Empty description="No data found" />
          </List.Item>
        </List>
      ) : null}
    </>
  );
};

export default SearchBar;