import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getAllCourseByClusterId } from '../Slices/CoachmiSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as TamilIcon } from '../assets/images/tamilIcon.svg'
import { ReactComponent as EnglishIcon } from '../assets/images/englishIcon.svg'
import '../styles/AllCourses.scss'
import { Image, Tooltip } from "antd";
import BackIcon from '../assets/images/back_icon.svg'
import { addBreadCrumbData } from '../Slices/HomePageSlice';
import Loading from "../Component/Loading.js";
import { CoursePricingHelper, renderVerticalLineHelper } from '../utlis/HelperFunctions.js';

const AllCourse = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { allCourseData, isLoading } = useSelector((state) => state.Coachmi);

  useEffect(() => {
    dispatch(addBreadCrumbData({ isCourseList: true }))

    dispatch(getAllCourseByClusterId({ clusterId: params.clusterId, isLimitedData: false }))
  }, [])

  const navigateToCourse = (courseMapId) => {
    navigate(`/home/course/${courseMapId}`)
  }

  const goBack = () => {
    navigate('/home/coachmi')
  }

  return (
    <>
      {
        isLoading ? (
          <div className='loading-container'>
            <Loading></Loading>
          </div>
        ) : (
          <div className="overAll-course-contaniner">
            <div className='course-header'>
              <Image preview={false} src={BackIcon} className="back-icon" onClick={() => goBack()} />
              <p>Courses</p>
            </div>

            <div className="courses-container">
              <div className="courses-grid">
                {allCourseData.map((course, index) => {

                  const hasDuration = Boolean(course['duration']);
                  const hasTests = course['testCount'] > 0;
                  const hasDocuments = course['documentCount'] > 0;

                  return (
                    <div className="course-card" key={index} onClick={() => navigateToCourse(course?.courseMappingId)}>
                      {
                        course.courseThumbnailUrl ? (
                          <img
                            src={course.courseThumbnailUrl}
                            alt=''
                            className="course-image"
                          />
                        ) : (
                          <div className="course-image course-image-default"></div>
                        )
                      }
                      <div className="course-tag">
                        <p className="course-type">{course.videoType}</p>
                        {course.languageId === 1 ? (
                          <p>
                            <TamilIcon />
                          </p>
                        ) : (
                          <p>
                            <EnglishIcon />
                          </p>
                        )}
                      </div>
                      <Tooltip title={course.clusterGroupName}>
                        <p className="course-clusterGroup">{course.clusterGroupName}</p>
                      </Tooltip>
                      <Tooltip title={course.courseName}>
                        <p className="course-title">{course.courseName}</p>
                      </Tooltip>
                      <div className="course-details">

                        {hasDuration && (
                          <>
                            <p className="courseHours">{course['duration']}</p>
                            {renderVerticalLineHelper(hasTests || hasDocuments)}
                          </>
                        )}

                        {hasTests && (
                          <>
                            <p className="courseTests">{course['testCount']} Test Series</p>
                            {renderVerticalLineHelper(hasDocuments)}
                          </>
                        )}

                        {hasDocuments && (
                          <p className="courseDocs">{course['documentCount']} Documents</p>
                        )}

                      </div>
                      {CoursePricingHelper(course)}
                    </div>
                  )
                }
                )}
              </div>
            </div>
          </div>
        )
      }

    </>
  );
}

export default AllCourse
