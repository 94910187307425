import { useNavigate } from 'react-router-dom';

const useBackNavigation = () => {
  const navigate = useNavigate();

  const goBack = (path = null) => {
    if (!path) {
      navigate(-1);
    } else {
      navigate(`${path}`);
    }
  };

  return goBack;
};

export default useBackNavigation;
