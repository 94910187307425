import React, { useEffect, useState } from "react";
import '../styles/ContentList.scss';
import { Select, Image, Button } from "antd";
import {  getContentByClassId, getContentFilter, getTopicDetailsById, getSubscriptionPlan, getContentFilterbyTopic, getContentFilterbySubTopic, removesubscription } from "../Slices/CoachmiSlice";
import { useDispatch, useSelector } from "react-redux";
import dotImg from "../assets/images/dotImg.svg";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import BackIcon from '../assets/images/back_icon.svg';
import { ECoachmiBreadCrumbData, ECoachmiSubscription } from "../utlis/enum";
import { getCoachmiBreadCrumb } from "../Slices/HomePageSlice";
import useBackNavigation from '../hooks/useBackNavigation';
import { getLeastMonthsSubscriptionHelper, setLocalStorageItemHelper } from '../utlis/HelperFunctions.js';
import Loading from "../Component/Loading.js";
import { ReactComponent as TamilIcon } from "../assets/images/tamilIcon.svg";
import { ReactComponent as EnglishIcon } from "../assets/images/englishIcon.svg";
import moment from "moment";
import { Tooltip } from 'antd';


const { Option } = Select;

const TopicSelector = ({ contentFilterData, topicMapId, selectedTopic, handleTopicChange }) => {
  return (
    <Select
      id="topicSelector"
      name="topicSelector"
      showSearch
      filterOption={(input, option) =>
        option.children.toLowerCase().includes(input.toLowerCase())
      }
      placeholder="Select Topic"
      onChange={handleTopicChange}
      defaultValue={topicMapId}
      value={selectedTopic}
    >
      {contentFilterData.map((topic) => (
        <Option key={topic.topicMapId} value={topic.topicMapId}>
          {topic.value}
        </Option>
      ))}
    </Select>
  );
};


const Content = () => {
  const dispatch = useDispatch();
  const { courseMappingId, classMapId, clusterGroupMappingId, topicMapId } = useParams();
  const navigate = useNavigate();
  const { Option } = Select;
  const location = useLocation();
  const { slugId } = location.state || {};
  const goBack = useBackNavigation();
  const [goBackPath, setGoBackPath] = useState(clusterGroupMappingId ? `/home/Course/${clusterGroupMappingId}/${courseMappingId}` : `/home/Course/${courseMappingId}`);

  const { ContentByClassData, ContentFilterData, topicDetails, subscriptionData, isContentLoading } = useSelector((state) => state.Coachmi);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedSubTopic, setSelectedSubTopic] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [filteredSubTopics, setFilteredSubTopics] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [clearSelectFlag, setClearSelectFlag] = useState(false);

  useEffect(() => {
    const payload = {
      type: ECoachmiBreadCrumbData.TOPIC,
      slugId: slugId,
    };
    dispatch(removesubscription());
    dispatch(getCoachmiBreadCrumb(payload));
    dispatch(getTopicDetailsById({ id: courseMappingId }));
    dispatch(getSubscriptionPlan({ type: ECoachmiSubscription.TOPIC, id: topicMapId, queryParams: { isLeastPrice: true } }));
    dispatch(getContentFilter({ id: courseMappingId })).unwrap().then((response) => {
      dispatch(getContentFilterbyTopic(topicMapId)).unwrap().then((res) => {
        // setSelectedTopic(topicMapId)
        const topic = response.data.find(item => +item.topicMapId === +topicMapId);
        if (topic) {
          setFilteredSubTopics(topic.subTopicOptions);
          setFilteredClasses([]);
        } else {
          setFilteredSubTopics([]);
          setFilteredClasses([]);
        }
      });
    });
  }, [dispatch, courseMappingId, slugId]);

  useEffect(() => {
    if (selectedClass) {
      dispatch(getContentByClassId(selectedClass));
    }
  }, [selectedClass, dispatch]);

  const handleTopicChange = (value) => {
    setSelectedTopic(value);
    if (value) {
      dispatch(getContentFilterbyTopic(value));
      dispatch(getSubscriptionPlan({
          type: ECoachmiSubscription.TOPIC,
          id: value,
          queryParams: { isLeastPrice: true },
        })
      );
    }
    setSelectedSubTopic(null);
    setSelectedClass(null);
    const topic = ContentFilterData.find((item) => item.topicMapId === value);
    if (topic) {
      setFilteredSubTopics(topic.subTopicOptions);
      setFilteredClasses([]);
    } else {
      setFilteredSubTopics([]);
      setFilteredClasses([]);
    }
  };

  const handleSubTopicChange = (value) => {
    console.log("subtopic change", value, clearSelectFlag);
    if (clearSelectFlag) return;
    setSelectedSubTopic(value);
    if (value) {
      dispatch(getContentFilterbySubTopic(value));
      dispatch(getSubscriptionPlan({
          type: ECoachmiSubscription.SUB_TOPIC,
          id: value,
          queryParams: { isLeastPrice: true },
        })
      );
    } else {
      dispatch(getSubscriptionPlan({
          type: ECoachmiSubscription.TOPIC,
          id: selectedTopic,
          queryParams: { isLeastPrice: true },
        })
      );
    }
    setSelectedClass(null);
    const topic = ContentFilterData.find(
      (item) => item.topicMapId === selectedTopic
    );
    if (topic) {
      const subTopic = topic.subTopicOptions.find(
        (option) => option.subTopicMapId === value
      );
      if (subTopic) {
        const uniqueClasses = Array.from(
          new Set(subTopic.classOptions.map((cls) => cls.classMapId))
        ).map((id) =>
          subTopic.classOptions.find((cls) => cls.classMapId === id)
        );
        setFilteredClasses(uniqueClasses);
      } else {
        setFilteredClasses([]);
      }
    }
  };

  const handleClassChange = (value) => {
    setSelectedClass(value);
    if (value) {
      dispatch(getSubscriptionPlan({
          type: ECoachmiSubscription.CLASS,
          id: value,
          queryParams: { isLeastPrice: true },
        })
      );
    } else {
      dispatch(getSubscriptionPlan({
          type: ECoachmiSubscription.SUB_TOPIC,
          id: selectedSubTopic,
          queryParams: { isLeastPrice: true },
        })
      );
    }
  };

  const navigateToContent = (content) => {
    navigate(`/home/content/${content?.contentMapId}`, { state: { slugId: content?.slugId } });
  };

  const handleSubscribeClick = () => {
    setLocalStorageItemHelper('subscriptionGoBackPath', location.pathname)
    navigate(`/home/subscriptionPlan/${courseMappingId}/?type=${ECoachmiSubscription.TOPIC}`);
  };

  const leastSubscription = getLeastMonthsSubscriptionHelper(subscriptionData?.planPricing);

  useEffect(() => {
    setSelectedTopic(+topicMapId);
  }, [+topicMapId]);

  const handleClearClass = () => {
    setSelectedClass(null);
    dispatch(getContentFilterbySubTopic(selectedSubTopic));
  }

  const handleClearSubTopic = () => {
    console.log('clear subtopic', selectedTopic, clearSelectFlag, selectedSubTopic)
    setClearSelectFlag(true)
    dispatch(getContentFilterbyTopic(selectedTopic));
    setSelectedSubTopic(null);
    setClearSelectFlag(false)
  }


  return (
    <>
        <div className="course-content-screen">
        <div className="content-header">
          <Image
            preview={false}
            src={BackIcon}
            className="back-icon"
            onClick={() => goBack(goBackPath)}
          />
          <p>{topicDetails.topicName}</p>
        </div>
  
        {(subscriptionData['planPricing']?.length > 0 && !subscriptionData['isValidSubscription']) && (
          <div className="price-card">
            <div className="price-info">
              <p className="current-price"> {leastSubscription?.price && '₹' + leastSubscription?.price} only</p>
              <div className="discount-info">
                {/* <p className="original-price">₹ {price.originalPrice}</p> */}
                {/* <p className="discount">{price.discount} % off</p> */}
              </div>
            </div>
            <button className="buy-button" onClick={() => handleSubscribeClick()}>Buy</button>
          </div>
        )}
  
        <div className="content-filter">
          <div className='form-control'>
            {
              ContentFilterData.length > 0 ? (
                <TopicSelector
                  contentFilterData={ContentFilterData}
                  topicMapId={topicMapId}
                  selectedTopic={selectedTopic}
                  handleTopicChange={handleTopicChange}
                />
              ) : null
            }
          </div>
  
          <div className='form-control'>
            <Select
              id="subTopicSelector"
              name="subTopicSelector"
              showSearch
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              placeholder="Select Sub-topic"
              onChange={handleSubTopicChange}
              allowClear
              value={selectedSubTopic}
              onClear={handleClearSubTopic}
            >
              {filteredSubTopics.map((subTopic) => (
                <Option key={subTopic.subTopicMapId} value={subTopic.subTopicMapId}>
                  {subTopic.value}
                </Option>
              ))}
            </Select>
          </div>
  
          <div className='form-control'>
            <Select
              id="classSelector"
              name="classSelector"
              showSearch
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              placeholder="Select Class"
              onChange={handleClassChange}
              allowClear
              value={selectedClass}
              onClear={handleClearClass}
            >
              {filteredClasses.map((cls) => (
                <Option key={cls.classMapId} value={cls.classMapId}>
                  {cls.value}
                </Option>
              ))}
            </Select>
          </div>
        </div>
  
        <div className="courses-grid">
          {
            isContentLoading ? (<Loading></Loading>) : (
              <>
                {ContentByClassData?.map((content, index) => (
                  <div className="course-card" key={index} onClick={() => navigateToContent(content)}>
                    {
                      content.contentThumnail ? (
                        <img src={content.contentThumnail} alt={content.className} className="course-image" />
  
                      ) : (
                        <div className="course-image course-image-default"></div>
                      )
                    }
                    <div className="course-tag">
                      {content.languageTypeId === 1 ? (
                        <p>
                          <TamilIcon />
                        </p>
                      ) : (
                        <p>
                          <EnglishIcon />
                        </p>
                      )}
                      <Tooltip title={content.className}>
                        <p className="content-topic-name">{content.className}</p>
                      </Tooltip>
                    </div>
                    <Tooltip title={content.contentName}>
                      <p className="content-name">{content.contentName}</p>
                    </Tooltip>
                    <p className="content-educator">{content.educatorName}</p>
                    <div className="course-details">
                      <p className="courseHours">{moment(content.contentCreatedAt).format('DD MMM')}</p>
                      <img src={dotImg} alt="Description" />
                      <p className="courseTests">{content.watchedCount} Watched</p>
                    </div>
                  </div>
                ))}
              </>
            )
          }
        </div>
      </div>
    </>
   
  );
};

export default Content;



