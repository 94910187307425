import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const ComponentLoading = () => {
    const customIcon = <LoadingOutlined className='custom-loading-icon' spin />;

    return (
        <div className='component-loading'>
            <Spin indicator={customIcon} size="small" />
            <p className='custom-loading-icon'>Loading</p>
        </div>
    )
}
export default ComponentLoading;