import { Badge, Button, Col, Drawer, Image, Input, message, Modal, Radio, Row, Spin } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import logo from '../assets/images/Centum_App_logo.svg'
import timer from '../assets/images/timer.svg'
import report from '../assets/images/report.svg'
import badge from '../assets/images/badge.svg'
import blankBadge from '../assets/images/blankBadge.svg'
import filledBadge from '../assets/images/filledBadge.svg'
import openArrow from '../assets/images/openArrow.svg'
import moment from 'moment'
import QuestionAnswer from '../Component/QuestionAnswer'
import { useFormik } from 'formik'
import { useDispatch, useSelector} from 'react-redux'
import { clearAnswerByQuestion, EndTest, setQuestionBookMark, submitEachQuestion, EndTestInstitutionStudent } from '../Slices/TestPageSlice'
import { useNavigate, useParams } from 'react-router-dom'
import Countdown from 'antd/lib/statistic/Countdown'
import { LoadingOutlined } from '@ant-design/icons'
import Loading from '../Component/Loading'
import { GetTestById } from '../Slices/TestSeriesSlice'
import * as Yup from 'yup';
import { useCallbackPrompt } from '../hooks/useCallbackPrompt'
import { raiseADispute, stopTakeTest } from '../Slices/TestListSlice'
import RequiredAuth from '../Component/RequiredAuth'
const { TextArea } = Input;

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
  />
);



const TestPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const [showDialog, setShowDialog] = useState(false)
  const [showBookmark, setShowBookmark] = useState(false)
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);

  const [currentTime, setCurrentTime] = useState(moment().format('h:mm:ss'))
  const { isLoadingTestPage, questionData, testData, getBookMarkQuestion, bookMarkDetails, continueTestData } = useSelector((state) => state.TestMaster)
  const { isLoading, getTestById } = useSelector((state) => state.TestSeries)

  const [showQuestionPanel, setShowQuestionPanel] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [currentTitle, setCurrentTitle] = useState("Instruction");
  const [reportDrawerVisible, setReportDrawerVisible] = useState(false);
  const [currentReportTitle, setCurrentReportTitle] = useState("Report");
  const [feedBack, setFeedBack] = useState("");
  const [initialValues, setInitialValues] = useState({
    optionSelected: 0
  })
  const [deadline, setDeadline] = useState(moment().add(getTestById.timeLimit ? getTestById.timeLimit : continueTestData.timeLimit, 'minutes'));
  // const [deadline, setDeadline] = useState(moment().add(1, 'minutes'));
  const [lastMin, setLastMin] = useState(moment().add(40, 'seconds'));
  let count = 0;
  const [currentIndexValue, setCurrentIndexValue] = useState(0)
  const [currentData, setCurrentData] = useState(questionData[currentIndexValue])
  let [defaultOption, setDefaultOption] = useState(0)
  const [buttonState, setButtonState] = useState(false)
  const testId = testData.testMasterStudentMapId;
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: ((values) => {
      let answerId = [values.optionSelected]
      let answerObj = questionData[currentIndexValue]
      if (questionData.length - 1 > currentIndexValue) {
        setCurrentIndexValue(currentIndexValue + 1)
      }
      if (questionData.length - 1 == currentIndexValue) {
        setIsModalVisible(true)

      }
      let obj = {
        id: answerObj.id,
        answerId: answerId,
        testDataId: testId
      }
      if (formik.values.optionSelected) {

        dispatch(submitEachQuestion(obj))
      }
    })
  })

  const saveAndNext = () => {
    setShowBookmark(false)
    if (questionData.length - 1 > currentIndexValue) {
      // let tempQuestionData = JSON.parse(JSON.stringify(questionData[currentIndexValue + 1]))
      setCurrentData(questionData[currentIndexValue + 1])
      formik.handleSubmit()
    }
    if (questionData.length - 1 == currentIndexValue) {
      formik.handleSubmit()
    }
  }
  const previous = () => {
    if (0 < currentIndexValue) {
      setCurrentIndexValue(currentIndexValue - 1)
      setCurrentData(questionData[currentIndexValue - 1])
    }

    if (0 > currentIndexValue) {
    }
  }

  const clearResponse = () => {
    let obj = {
      id: currentData.id,
      testDataId: testId
    }
    const newData = JSON.parse(JSON.stringify(currentData));
    newData.option.forEach((e) => e.value = false)
    setCurrentData(newData)
    dispatch(clearAnswerByQuestion(obj))
  }
  const markForReviewNext = () => {
    let obj = {
      id: currentData.id,
      answerId: [formik.values.optionSelected],
      testDataId: testId
    }
    if (questionData.length - 1 > currentIndexValue) {
      if (!showBookmark) {
        dispatch(setQuestionBookMark(obj))
      }

      setCurrentData(questionData[currentIndexValue + 1])
      setCurrentIndexValue(currentIndexValue + 1)
      setShowBookmark(false)
    }
    if (questionData.length - 1 === currentIndexValue) {
      if (!showBookmark) {
        dispatch(setQuestionBookMark(obj))
      }

    }
    if (formik.values.optionSelected) {

      dispatch(submitEachQuestion(obj))
    }
  }
  const bookMarkQuestion = () => {
    setShowBookmark(!showBookmark)
    let obj = {
      id: currentData.id,
      testDataId: testId
    }
    if (questionData.length - 1 > currentIndexValue) {

      dispatch(setQuestionBookMark(obj))

    }
    if (questionData.length - 1 == currentIndexValue) {
      dispatch(setQuestionBookMark(obj))

    }
  }

  const changeIndexValue = (index) => {
    setCurrentData(questionData[index])
    setCurrentIndexValue(index)


  }
  const onFinish = () => {
    setShowDialog(false)
    setButtonState(true)
    let obj = {
      id: currentData.id,
      answerId: [formik.values.optionSelected],
      testDataId: testId
    }
    dispatch(EndTest(obj)).unwrap().then((res) => {
      if (res.status) {
        message.success(res.message)
        navigate('home/solutions/' + testId)
      }
    })
  };
  const timerOnChange = (data) => {
    if (20 == Math.floor(data / 1000)) {
      if (count == 0) {
        count++;
        message.error("Last 20 seconds to end the Test")
      }
    }
    if (Math.floor(data / 1000) == 0) {
      setButtonState(true)
    }
  }
  const endTest = () => {
    setIsModalVisible(true)
  }


  const handleOk = () => {
    setShowDialog(false)
    setIsModalVisible(false);
    let obj = {
      id: currentData.id,
      answerId: [formik.values.optionSelected],
      testDataId: testId
    }
    dispatch(EndTest(obj)).unwrap().then((res) => {
      if (res.status) {
        message.success(res.message)
        navigate(`/home/solutions/${testId}`)
      }
    })
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const pauseTest = () => {
  }
  const openDrawer = () => {
    setDrawerVisible(true)

  }
  const closeDrawer = () => {
    setDrawerVisible(false)

  }
  useEffect(() => {
    setShowDialog(true)


  }, [])

  // useEffect(() => {
  //   setCurrentData(questionData[currentIndexValue])
  // }, [questionData])

  useEffect(() => {
    if (getBookMarkQuestion[currentIndexValue].bookMarkQuestion) {
      setShowBookmark(true)
    } else {
      setShowBookmark(false)
    }
  }, [currentIndexValue])

  const feedBackFormik = useFormik({
    initialValues: { feedBack: "" },
    validationSchema: Yup.object({
      feedBack: Yup.string().required("feedBack is Required")
    }),
    onSubmit: (values) => {
      setReportDrawerVisible(false)

      let data = {
        questionId: +currentData.questionId,
        feedBack: values.feedBack
      }
      dispatch(raiseADispute(data)).unwrap().then((res) => {
        if (res.status) {
          message.success(res.message)
        } else {
          message.error("Error In Submitting Report")
        }
      })



    }
  })
  const openReportDrawer = () => {
    feedBackFormik.resetForm()
    setReportDrawerVisible(true)
  }
  const QuestionPanel = () => {
    setShowQuestionPanel(!showQuestionPanel)
  }
  const currentBookmark = () => {
    if (!showBookmark && getBookMarkQuestion[currentIndexValue].bookMarkQuestion) {
      return false
    }
    else if (showBookmark) {
      return true
    } else if (!showBookmark) {
      return false
    } else {
      return true
    }

  }

  return (
    <>
      <div className='test-page-container'>
        <div className='question-container'>
          <div className='question-container-body'>


            <div className='question-header'>
              <Image preview={false} src={logo} />
              <span className='timer-content'>
                <Image preview={false} src={timer} />
                <span className='timer-text'>
                  <Countdown value={deadline} onChange={timerOnChange} onFinish={onFinish} />
                </span>
              </span>
            </div>
            <div className='question-details'>
              <div className='question-details-header'>
                <span className='question-number'> Question {currentIndexValue + 1} </span>
                <div className='question-flags'>
                  <span className='status'>
                    {
                      testData.negativeMark ?
                        <span className='wrong-status'>{testData.negativeMark}</span>
                        : null
                    }
                    <span className='correct-status'>{testData.eachQuestionMark}</span>
                  </span>
                  <span className='bookmark' onClick={() => { bookMarkQuestion() }}> <Image preview={false} src={currentBookmark() ? filledBadge : blankBadge} /><span>Bookmark</span></span>
                  <span className='report' onClick={() => openReportDrawer()} ><Image preview={false} src={report} /><span className='report-text' >Report</span></span>

                </div>

              </div>
              <div className='question-details-content'>
                <QuestionAnswer currentData={currentData} currentIndex={currentIndexValue + 1} formik={formik} defaultOption={defaultOption} />
              </div>

            </div>
          </div>
          <div className='question-details-footer'>
            <div className='markup-clear-button'>
              <Button type='primary' className='mark-review-button' onClick={() => markForReviewNext()} >Mark for Review & Next </Button>
              <Button type='primary' className='outline-test-button' onClick={() => clearResponse()}  >Clear Response</Button>
            </div>
            <div className='previous-save-next-button'>
              <Button type='primary' className='outline-test-button' disabled={currentIndexValue == 0} onClick={() => previous()} >Previous</Button>
              <Button type='primary' className='filled-test-button' onClick={() => saveAndNext()} >Save & Next</Button>
              {/* 
              <Button type='primary' className='outline-test-button' disabled={currentIndexValue ==  0} onClick={() => previous()} >Previous</Button>
              <Button type='primary' className='filled-test-button'  disabled={currentIndexValue ==  questionData.length } onClick={() => saveAndNext()} >Save & Next</Button> */}
            </div>
          </div>
        </div>
        <div className={!showQuestionPanel ? 'question-buttons' : 'question-buttons show-panel'}>
          <div className="question-button-content" >
            <div className={!showQuestionPanel ? "mobile-btn-toggle" : "close-btn-toggle"} onClick={() => QuestionPanel()}>
              <Image className={showQuestionPanel ? "closeArrow" : ''} preview={false} src={openArrow}></Image>
            </div>
            <h2 className='test-name'>{getTestById?.testName}</h2>
            <div className='question-pad-details'>
              <p className='question-header'>Questions</p>
              <p className='total-question'>{questionData?.length} questions</p>
              <div className='marker-details'>
                <span className='marker-data'> <Badge status="success" /> {bookMarkDetails?.answered} answered</span>
                <span className='marker-data'>  <Badge status="warning" />  {bookMarkDetails?.marked} Marked</span>
                <span className='marker-data'>  <Badge status="default" />  {bookMarkDetails?.unAnswered} unanswered</span>

              </div>
              <div className='question-button-status'>
                <Row>
                  {getBookMarkQuestion.length ?
                    getBookMarkQuestion.map((e, index) => {
                      if (e.bookMarkQuestion && e.isAnswered) {
                        return (
                          <Col key={index + 1} xs={6} md={8} lg={4}> <Button type='primary' className={currentIndexValue == index ? 'answered-question current-question' : 'answered-question'} onClick={() => changeIndexValue(index)}>{index + 1} <span className='badge'><Image preview={false} src={badge}></Image></span></Button></Col>
                        )
                      }
                      else if (e.bookMarkQuestion && !e.isAnswered) {
                        return (
                          <Col key={index + 1} xs={6} md={8} lg={4}> <Button type='primary' className={currentIndexValue == index ? 'not-answered-question current-question' : 'not-answered-question'} onClick={() => changeIndexValue(index)} >{index + 1}<span className='badge'><Image preview={false} src={badge}></Image></span></Button></Col>
                        )
                      }
                      else if (e.isAnswered) {
                        return (
                          <Col key={index + 1} xs={6} md={8} lg={4}><Button type='primary' className={currentIndexValue == index ? 'answered-question current-question' : 'answered-question'} onClick={() => changeIndexValue(index)}>{index + 1}</Button></Col>
                        )
                      }
                      else {
                        return (
                          <Col key={index + 1} xs={6} md={8} lg={4}><Button type='primary' className={currentIndexValue == index ? 'not-answered-question current-question' : 'not-answered-question'} onClick={() => changeIndexValue(index)}>{index + 1}</Button></Col>

                        )
                      }
                    })
                    : ""
                  }


                </Row>

              </div>

            </div>
          </div>
          <div className='question-panel-footer-button'>
            <div className='footer-link-page'>
              <span>Question Paper</span><span onClick={() => openDrawer()}>View Instruction</span>
            </div>
            <div className='footer-submit-button'>
              {/* <Button type='primary' className='end-test-pause-button' onClick={() => pauseTest()}>Pause</Button> */}
              <Button type='primary' className={buttonState ? 'time-over-button' : 'end-test-submit-button'} onClick={() => endTest()}>Finish</Button>
            </div>

          </div>
          <Modal title="Basic Modal" className='endTest-modal' okText="Finish" closable={false} cancelText="Close" centered visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            <div className='modal-container'>
              <p className='modal-header'>Are you sure to end the test?</p>
              <p className='modal-sub-header'>Questions summary</p>
              <div className='marker-details'>
                <span className='marker-data'> <Badge status="success" /> {bookMarkDetails?.answered} answered</span>
                <span className='marker-data'>  <Badge status="warning" />  {bookMarkDetails?.marked} Marked</span>
                <span className='marker-data'>  <Badge status="default" />  {bookMarkDetails?.unAnswered} unanswered</span>

              </div>
            </div>
          </Modal>

        </div>
        <Drawer title={currentTitle} onClose={() => setDrawerVisible(false)} width="518px" className='testPageDrawer' closable={false} closeIcon={false} placement="right" visible={drawerVisible} footer={(
          <div className='footer'>
            <div className='footer-button'>
              <Button className='cancel-Button primary-cancel-button' type='primary' onClick={() => closeDrawer()} >Close</Button>
            </div>
          </div>
        )}>
          <div className='test-instruction-data'>
            <div dangerouslySetInnerHTML={{ __html: getTestById?.instructions }} />

          </div>

        </Drawer>
        <Drawer title={currentReportTitle} onClose={() => setReportDrawerVisible(false)} width="518px" className='reportDrawer' closable={false} closeIcon={false} placement="right" visible={reportDrawerVisible} footer={(
          <div className='footer'>
            <div className='footer-button'>
              <Button className='cancel-Button primary-cancel-button' type='primary' onClick={() => setReportDrawerVisible(false)} >Cancel</Button>
              <Button className='primary-submit-button' type="primary" disabled={!feedBackFormik.dirty || !feedBackFormik.isValid} onClick={feedBackFormik.handleSubmit}   >Submit </Button>
            </div>
          </div>
        )}>
          <div className='report-container'>
            <p className='feedBack-header'>Your feedback will help us to improve your test taking experience</p>
            {/* {JSON.stringify(feedBackFormik)} */}
            <form>
              <div className='form-control'>
                <label htmlFor='feedBack'>
                  Feedback :
                </label>
                <TextArea type='text' id='feedBack' placeholder="Type Here" style={{ height: 120, resize: 'none' }} onBlur={feedBackFormik.handleBlur} onChange={feedBackFormik.handleChange} value={feedBackFormik.values.feedBack} />
                {/* <Input type='text' id='feedBack' placeholder="Type Here" onBlur={feedBackFormik.handleBlur} onChange={feedBackFormik.handleChange} value={feedBackFormik.values.feedBack} /> */}
                {feedBackFormik.touched.feedBack && feedBackFormik.errors.feedBack ? <div className='errorMessage' style={{ color: 'red' }}>{feedBackFormik.errors.feedBack}</div> : null}

              </div>
            </form>
          </div>

        </Drawer>

      </div>
      {
        isLoadingTestPage ? <div className='loading-container'>

          <Loading></Loading>

        </div> : null
      }
      {
        showPrompt ?
          <div>
            <Modal className="pageBackButton" title="Confirmation" visible={true} onOk={confirmNavigation} onCancel={cancelNavigation}>

              <div className='modal-container'>
                <p className='modal-header'>Are you sure to end the test?</p>
                {/* <p className='modal-sub-header'>Questions summary</p> */}
              </div>
            </Modal>
          </div>


          : null
      }


    </>
  )
  // }
}

export default TestPage















// <Col span={4}><Button type='primary' className='marked-question'>{getBookMarkQuestion[0].id}</Button></Col>
// <Col span={4}><Button type='primary' className='not-answered-question'>3</Button></Col>
// <Col span={4}><Button type='primary' className='answered-question'>4</Button></Col>
// <Col span={4}><Button type='primary' className='not-answered-question'>5</Button></Col>
// <Col span={4}><Button type='primary' className='not-answered-question'>6</Button></Col>
// <Col span={4}><Button type='primary' className='answered-question'>7</Button></Col>
// <Col span={4}><Button type='primary' className='not-answered-question'>8</Button></Col>
// <Col span={4}> <Button type='primary' className='marked-question'>9 <span className='badge'><Image preview={false} src={badge}></Image></span></Button></Col>
// <Col span={4}><Button type='primary' className='current-question'>10</Button></Col>