import React, { useEffect, useState } from 'react'
import { Button, Col, Image, Row } from 'antd'


const ViewQuestionSelection = ({ currentData, statusData, currentIndex }) => {
  const [isShow, setIsShow] = useState(false)
  let nodeComprehensive = React.createRef();
  let nodeNormal = React.createRef();
  useEffect(() => {
    renderMath();
  });
  const renderMath = () => {
    window.MathJax.Hub.Queue([
      "Typeset",
      window.MathJax.Hub,
      nodeComprehensive.current
    ]);
    window.MathJax.Hub.Queue([
      "Typeset",
      window.MathJax.Hub,
      nodeNormal.current
    ]);
  }
  useEffect(() => {
    setIsShow(false)
    renderMath();
  }, [currentData])
  // let statusData = 3;
  return (
    <div className='question-details-body'>
      {
        currentData?.comprehensiveQuestionsId ?
          <div style={{ paddingBottom: 15 }}>
            <p className='comprehension-label'><span className='Question_inline' ref={nodeComprehensive} dangerouslySetInnerHTML={{ __html: currentData?.comprehensiveQuestionsText }}></span></p>

          </div>
          :
          null
      }
      <div>
        {/* {JSON.stringify(currentData)} */}
        <p className='question-label'>Q{currentIndex}.  <span className='Question_inline' ref={nodeNormal} dangerouslySetInnerHTML={{ __html: currentData?.questionText }}></span></p>
        {
          currentData?.questionImage != null ?
            <Image preview={true} className="question-image" src={currentData?.questionImage}></Image>
            : null
        }
        <Row>
          {
            currentData?.option.map((data, index) => {

              return (
                data.value && data.id == currentData?.correctAnswer ?
                  <Col key={index} xs={24} sm={24} lg={12}> <span className='correct-option'>
                    <span className='radio-span'></span> <span>{data.name}</span>
                  </span> </Col>
                  : data.value && data.id != currentData.correctAnswer ?
                    <Col key={index} xs={24} sm={24} lg={12}> <span className='incorrect-option'>
                      <span className='radio-span'></span><span>{data.name}</span> </span> </Col>
                    : data.id == currentData.correctAnswer ?
                      <Col key={index} xs={24} sm={24} lg={12}> <span className='correct-option'>
                        <span className='radio-span'></span><span>{data.name}</span> </span> </Col>
                      :
                      <Col key={index} xs={24} sm={24} lg={12}> <span className='option'>
                        <span className='radio-span'></span><span>{data.name}</span> </span> </Col>
              )
            })}

          {/* <Col span={9}> <span className='option'> <span className='radio-span'></span>
     <span>col-4</span> </span> </Col>
    <Col span={9}> <span className='incorrect-option'>
     <span className='radio-span'></span><span>col-4</span> </span> </Col>
    <Col span={9}> <span className='option'>
     <span className='radio-span'></span><span>col-4</span> </span> </Col> */}
        </Row>
      </div>
      <div className='question-view-solution-container'>
        <Button type='primary' className='filled-test-button' onClick={() => isShow ? setIsShow(false) : setIsShow(true)} >Solution</Button>

        {
          isShow ?
            <div className='view-solution-for-question'>
              <p className='Question_inline' dangerouslySetInnerHTML={{ __html: currentData?.solution }}></p>
            </div>
            : null
        }
      </div>
    </div>
  )
}

export default ViewQuestionSelection