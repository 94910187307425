import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Select, Image, Button, Empty, Tooltip } from "antd";
import BackIcon from '../assets/images/back_icon.svg'
import { useNavigate, useParams, useLocation } from "react-router-dom";
import '../styles/Content.scss'
import EducatorsIcon from "../assets/images/educators.svg";
import PdfIcon from '../assets/images/PDF File.svg'
import DownloadIcon from '../assets/images/Download.svg'
import TamilIcon from "../assets/images/tamilIcon.svg";
import EnglishIcon from "../assets/images/englishIcon.svg";
import { getContentDetails, getContentEducator, getStudyMaterialsById, getAllRecommendedTest, educatorContentFollow, getContentVideoUrl, getSubscriptionPlan, removesubscription } from "../Slices/CoachmiSlice";
import { ECoachmiBreadCrumbData, ECoachmiSubscription, EContentType } from "../utlis/enum";
import { getCoachmiBreadCrumb } from "../Slices/HomePageSlice";
import UserIcon from '../assets/images/user_icon.svg'
import { setLocalStorageItemHelper } from "../utlis/HelperFunctions.js";
import Loading from '../Component/Loading';


const Content = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { contentMappingId } = useParams()
  const { contentData, studyMaterialsData, recommendedTestData, contentEducatorsData, pricePlanData, contentVideo, isLoading, subscriptionData } = useSelector((state) => state.Coachmi);
  const location = useLocation();
  const { slugId } = location.state || {};
  const { clusterId } = useParams();

  useEffect(() => {
    let payload = {
      type: ECoachmiBreadCrumbData.CONTENT,
      slugId: slugId,
    }
    dispatch(removesubscription());
    dispatch(getCoachmiBreadCrumb(payload))
    dispatch(getContentDetails(contentMappingId))
    dispatch(getStudyMaterialsById({ contentMapId: contentMappingId }))
    dispatch(getAllRecommendedTest({ id: contentMappingId }))
    dispatch(getContentEducator(contentMappingId))
    dispatch(getSubscriptionPlan({ type: ECoachmiSubscription.CONTENT, id: contentMappingId, queryParams: { isLeastPrice: true } }));
  }, [contentMappingId]);

  useEffect(() => {
    dispatch(getContentVideoUrl({ contentAttachmentId: contentData?.contentAttachmentId, type: EContentType.COACHMI_CONTENT }))
  }, [contentData])

  const goBack = () => {
    navigate('/home/coachmi')
  }

  const handleSubscribeClick = () => {
    setLocalStorageItemHelper('subscriptionGoBackPath', location.pathname)
    navigate(`/home/subscriptionPlan/${contentMappingId}/?type=${ECoachmiSubscription.CONTENT}`);
  };


  const handleFollow = (id) => {
    dispatch(educatorContentFollow({ id, contentMappingId, clusterId }))
  }

  const takeTest = (testId) => {
    navigate(`/home/previousYearTestInstruction/${testId}`)
  }

  return (
    <>
      {
        isLoading ? (
          <div className='loading-container'>
            <Loading></Loading>
          </div>
        ) : (
          <div className="content-overAll-container">
            <div className="content-header">
              <Image
                preview={false}
                src={BackIcon}
                className="back-icon"
                onClick={() => goBack()}
              />
              <p>{contentData?.contentName}</p>
            </div>
            {(subscriptionData['planPricing']?.length > 0 && !subscriptionData['isValidSubscription']) && (
              <div className="price-card">
                <div className="price-info">
                  <p className="current-price">
                    ₹ {pricePlanData?.currentPrice} only
                  </p>
                  <div className="discount-info">
                    <p className="original-price">₹ {pricePlanData?.originalPrice}</p>
                    <p className="discount">{pricePlanData?.discount} % off</p>
                  </div>
                </div>
                <button className="buy-button" onClick={() => handleSubscribeClick()}>
                  Buy
                </button>
              </div>
            )}
            <div className="content-container">
              <div className="content-wrapper">
                <div className="content-video-container">
                  <video width="100%" controls autoPlay>
                    <source
                      src={contentVideo ? contentVideo : ""}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className="content-overview-container">
                  <div className="educator-card">
                    <p className="educator-title">Educator</p>
                    <div className="scroll-bar">
                      {contentEducatorsData && contentEducatorsData.length > 0 ? (
                        contentEducatorsData?.map((educator, index) => (
                          <div key={index} className="educator-info">
                            <div className="educator-image">
                              <Image
                                preview={false}
                                src={
                                  educator.educatorImage
                                    ? educator.educatorImage
                                    : UserIcon
                                }
                                alt="educator image"
                              />
                            </div>
                            <div className="educator-details">
                              <div className="educator-details-wrapper">
                                <p className="educator-name">
                                  {educator.educatorName}
                                </p>
                                <p className="educator-followers">
                                  {educator.followingCount} Followers
                                </p>
                              </div>
                              <Button
                                className={`follow-button ${educator?.following == 1 ? "following" : ""
                                  }`}
                                onClick={() =>
                                  handleFollow(educator?.id)
                                }
                              >
                                {educator?.following === 1 ? "Following" : "Follow"}
                              </Button>
                            </div>
                          </div>
                        ))
                      ) : (
                        <Empty description="No educators found" />
                      )}
                    </div>
                  </div>

                  <div className="study-material">
                    <p className="study-material-title">Study Material</p>
                    <div className="pdf-scroll">
                      <div className="pdf-container">
                        {studyMaterialsData && studyMaterialsData.length > 0 ? (
                          studyMaterialsData.map((material, index) => (
                            <div key={index} className="pdf">
                              <div className="pdf-content-wrapper">
                                <Image preview={false} src={PdfIcon} />
                                <p className="pdf-title">{material.fileName}</p>
                              </div>
                              <div className="download-icon">
                                <a href={material.attachmentUrl}>
                                  <Image preview={false} src={DownloadIcon} />
                                </a>
                              </div>
                            </div>
                          ))
                        ) : (
                          <Empty description="No studymaterials found" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-about">
                <div className="content-type-container">
                  <p className="content-type">{contentData?.videoType}</p>
                  <Image
                    preview={false}
                    src={contentData?.languageId == 1 ? TamilIcon : EnglishIcon}
                    className="language-icon"
                  />
                </div>
                <p className="content-title">{contentData?.contentName}</p>
                <p className="content-description">
                  {contentData?.contentDescription}
                </p>
              </div>
              <div className="content-recommended-test">
                <p className="recommended-test">Recommended Test</p>
                <div className="test-cards">
                  {recommendedTestData.map((test) => (
                    <div key={test.id} className="test-card">
                      <div className="test-info">
                        <div className="test-type">
                          <Tooltip title={test.examGroupName}>
                            <span className="test-category">
                              {" "}
                              {test.examGroupName}
                            </span>
                          </Tooltip>
                        </div>
                        <Tooltip title={test.testName}>
                          <div className="test-title">{test.testName}</div>
                        </Tooltip>
                        <div className="test-details">
                          <span>{test.numberOfQuestions} Qs.</span>
                          <span>| {test.totalMarks} Marks</span>
                          <span>| {test.timeLimit} mins</span>
                        </div>
                      </div>
                      <Button
                        className="take-test-button"
                        onClick={() => takeTest(test?.testId)}
                      >
                        Take Test
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
}

export default Content
