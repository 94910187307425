import { Button } from 'antd';
import React from 'react';

const NoDataComponent = ({ content, goBackVisible = false, handleGoBack }) => {

    return (
        <div className="no-content-available">
            {content}
            {
                goBackVisible && (
                    <Button onClick={handleGoBack}>Go Back</Button>
                )
            }
        </div>
    );
};

export default NoDataComponent;
