import React, {  useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getAllTestSeriesByClusterId } from '../Slices/CoachmiSlice';
import { useNavigate, useParams } from 'react-router-dom';
import '../styles/CoachmiTestSeries.scss'
import { Button,Image, Tooltip } from "antd";
import BackIcon from '../assets/images/back_icon.svg'
import { addBreadCrumbData } from '../Slices/HomePageSlice';
import Loading from '../Component/Loading';


const CoachmiTestSeries = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { clusterId } = useParams()
    const { testData, isLoading } = useSelector((state) => state.Coachmi);

    useEffect(() => {
        dispatch(getAllTestSeriesByClusterId({clusterId:clusterId,isLimitedData:false}));
    },[]);

    const goBack = () => {
        navigate(`/home/coachmi`)
      };

      const takeTest = (testId) => {
        navigate(`/home/previousYearTestInstruction/${testId}`)
      }

  return (
  <>
  {
    isLoading? (
      <div className='loading-container'>
      <Loading></Loading>
      </div>
    ) : (
      <div className='test-series-container'>
      <div className="test-series-header">
            <Image
              preview={false}
              src={BackIcon}
              className="back-icon"
              onClick={() => goBack()}
            />
            <p>Test Series</p>
          </div>
          <div className="test-cards">
          {testData.map((test) => (
            <div key={test.id} className="test-card">
              <div className="test-info">
                <div className="test-type">
                  {test.testType} <span className="divider">/</span>
                 <Tooltip title={test.examGroupName}>
                  <span className="test-category"> {test.examGroupName}</span>
                  </Tooltip>
                </div>
                <Tooltip title={test.testName}>
                <div className="test-title">{test.testName}</div>
                </Tooltip>
                <div className="test-details">
                  <span>{test.numberOfQuestions} Qs.</span>
                  <span>| {test.totalMarks} Marks</span>
                  <span>| {test.timeLimit} mins</span>
                </div>
                <Button className="take-test-button" onClick={() => takeTest(test?.testId)}>Take Test</Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
     
  </>
  )
}

export default CoachmiTestSeries
