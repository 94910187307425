export const ECoachmiBreadCrumbData = {
  CLUSTER_GROUP: "cluster_group",
  COURSE: "course",
  TOPIC: "topic",
  CONTENT: "content",
};

export const ECoachmiSubscription = {
  CLUSTER: "cluster",
  CLUSTER_GROUP: "clusterGroup",
  COURSE: "course",
  TOPIC: "topic",
  SUB_TOPIC: "subTopic",
  CLASS: "class",
  CONTENT: "content",
};

export const EGobalSearchType = {
  CLUSTER_GROUP: "cluster_group",
  COURSE: "course",
  TOPIC: "topic",
  SUB_TOPIC: "sub_topic",
  CLASS: "class",
  CONTENT: "content"
};

export const EContentType = {
  OTHER_VIDEO_CONTENT: "otherVideoContent",
  COACHMI_CONTENT: "coachmiContent"
};