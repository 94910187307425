import { Col, Image, Radio, Row } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'

const QuestionAnswer = ({ currentData, currentIndex, formik }) => {
  let [defaultOption, setDefaultOption] = useState(0)
  let nodeComprehensive = React.createRef();
  let nodeNormal = React.createRef();
  useEffect(() => {
    renderMath();
  });
  const renderMath = () => {
    window.MathJax.Hub.Queue([
      "Typeset",
      window.MathJax.Hub,
      nodeComprehensive.current
    ]);
    window.MathJax.Hub.Queue([
      "Typeset",
      window.MathJax.Hub,
      nodeNormal.current
    ]);
  }
  useEffect(() => {
    renderMath();

    formik.setFieldValue('optionSelected', 0)

    currentData.option.map((e) => {
      if (e.value) {
        formik.setFieldValue('optionSelected', e.id)
      }

    })


  }, [currentData])
  const currentOnChange = (event, optionIndex) => {
    formik.setFieldValue('optionSelected', event.target.value)
  }

  return (
    <div className='question-answer-component'>
      {
        currentData?.comprehensiveQuestionsId ?
          <div>
            <p className='comprehension-text'><span className='Question_inline' ref={nodeComprehensive} dangerouslySetInnerHTML={{ __html: currentData?.comprehensiveQuestionsText }}></span></p>

          </div>
          :
          null
      }
      <p className='question-text'>Q{currentIndex}. <span className='Question_inline' ref={nodeNormal} dangerouslySetInnerHTML={{ __html: currentData.questionText }}></span></p>
      {
        currentData.questionImage != null
          ?
          <Image className='question-image' preview={true} src={currentData.questionImage}></Image>

          : null
      }
      <div className='answer-details'>
        <Radio.Group name="optionSelected" value={formik.values.optionSelected} >
          <Row>
            {
              currentData?.option?.map((e, optionIndex) => (
                <Col key={e.id}  xs={24} sm={24} lg={12} className="option">  <Radio tabIndex={e.id} value={e.id} onChange={(event) => currentOnChange(event, optionIndex)}  >{e.name}</Radio></Col>
              ))
            }
          </Row>
        </Radio.Group>
      </div>
    </div>
  )
}

export default QuestionAnswer