import './App.css';
import { Button } from 'antd';
import LayoutComponent from './Layout/Layout';
import { BrowserRouter, Route, Routes, useRoutes, Navigate } from 'react-router-dom';
import Home from './pages/home';
import Login from './pages/login';
import AllTest from './pages/allTest';
import TestBundles from './pages/testBundles';
import PurchaseTest from './pages/purchaseTest';
import AttemptedTest from './pages/attemptedTest';
import Result from './pages/result';
import Profile from './pages/profile';
import Test from './pages/test';
import TestSeries from './pages/testSeriesPage';
import SignUp from './pages/signUp';
import OtpPage from './pages/otpPage';
import Password from './pages/password';
import ForgotPassword from './pages/forgotPassword';
import ForgotPasswordInstitution from './pages/forgotPasswordInstitution';
import TestInstruction from './pages/testInstruction';
import TestPage from './pages/testPage';
import { NoMatchRoute } from './pages/noMatchRoute';
import { AuthProvider } from './utlis/Auth';
import RequiredAuth from './Component/RequiredAuth';
import Navbar from './Component/navBar';
import LandingPage from './pages/landingPage';
import RequiredLoginAuth from './Component/RequiredLoginAuth';
import TakeTest from './pages/takeTest';
import Cart from './pages/cart';
import AvailableTest from './pages/availableTest';
import QuestionBankTestComponent from './Component/QuestionBankTestComponent';
import Solutions from './pages/solutions'
import SolutionsInstitution from './pages/solutionsInstitution'
import SolutionsPreviousYearExam from './pages/solutionsPreviousYearExam'
import ViewSolution from './pages/viewSolution';
import ViewSolutionPreviousYearExam from './pages/viewSolutionPreviousYearExam';
import ViewSolutionInstitution from './pages/viewSolutionInstitution';
import OrderPage from './pages/orderPage';
import SingleOrder from './pages/singleOrder';
import Exam from './pages/exam';
import PopularTest from './pages/popularTest';
import GuardTestPage from './Component/GuardTestPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import AboutUs from './pages/AboutUs';
import RandCPolicy from './pages/RandCPolicy';
import ExamGroup from './pages/examGroup';
import Checkout from './pages/checkout';
import { useNavigate } from 'react-router-dom';
import InstitutionDashBoard from './pages/institutionDashBoard';
import InstitutionStudentTest from './pages/institutionStudentTest';
import TestInstructionInsStud from './pages/testInstructionInsStud';
import TestPageInsStud from './pages/testPageInsStud';
import PreviousYearExam from './pages/previous-year-exam';
import PreviousYearExamTest from './pages/previous-year-exam-test';
import PreviousYearExamInstruction from './pages/previousYearExamInstruction';
import PreviousYearTestInstructionIns from './pages/previousYearExamInstructionIns';
import TestPagePreviosYearExam from './pages/testPagePreviosYearExam';
import React, { useEffect, useState } from 'react'
import RankDetails from "./Component/RankDetails";
import RankdetailsInstituion from "./Component/RankdetailsInstituion";
import InstituionProfile from './pages/InstituionProfile';
import Coachmi from './pages/Coachmi';
import AllCourse from './pages/AllCourse';
import Content from './pages/Content'; 
import ClusterGroupCourse  from './pages/ClusterGroupCourse';
import Course from './pages/Course';
import ContentList from './pages/ContentList';
import SubscriptionPlan from './pages/SubscriptionPlan';
import AllFreeClass from './pages/AllFreeClass';
import AllOtherVideos from './pages/AllOtherVideos';
import CoachmiTestSeries from './pages/CoachmiTestSeries';
import AllEducators from './pages/AllEducators'
import AllClusterGroup from './pages/AllClusterGroup';


function App() {

  const [renderComponent, setRenderComponent] = useState(null);

  useEffect(() => {
    const institutionStudentValue = localStorage.getItem('institutionStudent');
    if (institutionStudentValue != 'true') {
      setRenderComponent(<Home />);
    } else {
      setRenderComponent(<InstitutionStudentTest />);
    }
  }, []);




  return (
    <>
      <Routes>
        <Route path='/' element={<RequiredLoginAuth> <Login /> </RequiredLoginAuth>} />
        <Route path='/login' element={<RequiredLoginAuth> <Login />  </RequiredLoginAuth>} />
        <Route path='/signUp' element={<RequiredLoginAuth>  <SignUp />  </RequiredLoginAuth>} />
        <Route path='/enterOtp' element={<RequiredLoginAuth> <OtpPage />  </RequiredLoginAuth>} />
        <Route path='/password/:token' element={<RequiredLoginAuth> <Password />  </RequiredLoginAuth>} />
        <Route path='/forgotPassword' element={<RequiredLoginAuth> <ForgotPassword />  </RequiredLoginAuth>} />
        <Route path='/testPage/:testId' element={<GuardTestPage><TestPage /> </GuardTestPage>} />
        <Route path='/testPageInsStudent/:testId' element={<GuardTestPage><TestPageInsStud /> </GuardTestPage>} />
        <Route path='/testPagePreviosYearExam/:testId' element={<GuardTestPage><TestPagePreviosYearExam /> </GuardTestPage>} />
        <Route path='viewSolution/:id' element={<RequiredAuth><ViewSolution /> </RequiredAuth>} />
        <Route path='viewSolutionPreviousYearExam/:id' element={<RequiredAuth><ViewSolutionPreviousYearExam /> </RequiredAuth>} />
        <Route path='viewSolutionInstitution/:id' element={<RequiredAuth><ViewSolutionInstitution /> </RequiredAuth>} />
        <Route path='/institutionHome' element={<RequiredAuth><LayoutComponent /></RequiredAuth>}>
          <Route path='insStudTest' element={<RequiredAuth><InstitutionStudentTest /></RequiredAuth>} />
          <Route path='testInstructionInsStud/:id/assignId/:assignId' element={<RequiredAuth> <TestInstructionInsStud /> </RequiredAuth>} />
          <Route path='previousYearTestInstruction/:id/assignId/:assignId' element={<RequiredAuth> <PreviousYearTestInstructionIns /> </RequiredAuth>} />
          <Route path='previousYearTestInstruction/:id' element={<RequiredAuth> <PreviousYearExamInstruction /> </RequiredAuth>} />
          <Route path={`rankDetailsPage/assignId/:assignId/batchId/:batchId/testId/:testId/type/:typeId`} element={<RequiredAuth><RankdetailsInstituion /></RequiredAuth>} />
          <Route path='instituionProfile' element={<RequiredAuth><InstituionProfile /> </RequiredAuth>} />
          <Route path='solutions/:id' element={<RequiredAuth><Solutions /> </RequiredAuth>} />
          <Route path='solutionsPreviousYearExam/:id' element={<RequiredAuth><SolutionsPreviousYearExam /> </RequiredAuth>} />
          <Route path='solutionsInstitution/:id' element={<RequiredAuth><SolutionsInstitution /> </RequiredAuth>} />
          <Route path='' element={<RequiredAuth> <InstitutionStudentTest /> </RequiredAuth>} />
        </Route>
        <Route path='/home' element={<RequiredAuth> <LayoutComponent /> </RequiredAuth>}>
          <Route path={`rankDetailsPage/testId/:testId/type/:typeId`} element={<RequiredAuth><RankDetails /></RequiredAuth>} />
          <Route index element={<RequiredAuth> <Home /> </RequiredAuth>} />
          <Route path='allTest' element={<AllTest />} />
          <Route path='testSeries' element={<RequiredAuth> <TestBundles /> </RequiredAuth>} />
          <Route path='purchaseTest' element={<RequiredAuth>  <PurchaseTest /> </RequiredAuth>} />
          <Route path='testSeries/:id' element={<RequiredAuth>  <TestSeries /> </RequiredAuth>} />
          <Route path='testInstruction/:id' element={<RequiredAuth> <TestInstruction /> </RequiredAuth>} />
          <Route path='attemptedTest' element={<RequiredAuth> <AttemptedTest /> </RequiredAuth>} />
          <Route path='previousYearTestInstruction/:id' element={<RequiredAuth> <PreviousYearExamInstruction /> </RequiredAuth>} />
          {/* <Route path='attemptedTest' element={<RequiredAuth> <AttemptedTest /> </RequiredAuth>} /> */}
          <Route path='orderPage' element={<RequiredAuth> <OrderPage /> </RequiredAuth>} />
          <Route path='orderPage/:id' element={<RequiredAuth> <SingleOrder /> </RequiredAuth>} />
          <Route path='availableTest' element={<RequiredAuth> <AvailableTest /></RequiredAuth>} />
          <Route path='questionBankTest/:categoryId' element={<RequiredAuth> <QuestionBankTestComponent /></RequiredAuth>} />
          <Route path='profile' element={<RequiredAuth><Profile /> </RequiredAuth>} />
          <Route path='previousYearExam' element={<RequiredAuth><PreviousYearExam /></RequiredAuth>} />
          <Route path='previousYearExamTest' element={<RequiredAuth><PreviousYearExamTest /></RequiredAuth>} />
          <Route path='testList/:id' element={<RequiredAuth><TakeTest /> </RequiredAuth>} />
          <Route path='myCart' element={<RequiredAuth><Cart /> </RequiredAuth>} />
          <Route path='solutions/:id' element={<RequiredAuth><Solutions /> </RequiredAuth>} />
          <Route path='solutionsPreviousYearExam/:id' element={<RequiredAuth><SolutionsPreviousYearExam /> </RequiredAuth>} />
          <Route path='solutionsInstitution/:id' element={<RequiredAuth><SolutionsInstitution /> </RequiredAuth>} />
          <Route path='exams' element={<RequiredAuth> <ExamGroup></ExamGroup> </RequiredAuth>} />
          <Route path='exams/:slugId' element={<RequiredAuth> <Exam></Exam> </RequiredAuth>} />
          <Route path='popularTest' element={<RequiredAuth> <PopularTest></PopularTest> </RequiredAuth>} />
          <Route path='checkout' element={<RequiredAuth> <Checkout /> </RequiredAuth>} />
          <Route path='coachmi' element={<RequiredAuth> <Coachmi/> </RequiredAuth>} />
          <Route path='coachmi/allCourse/:clusterId' element={<RequiredAuth> <AllCourse/> </RequiredAuth>} />
          <Route path='content/:contentMappingId' element={<RequiredAuth> <Content/> </RequiredAuth>} />
          <Route path='allCourse/:clusterId' element={<RequiredAuth> <AllCourse/> </RequiredAuth>} />
          <Route path='allFreeClass/:clusterId' element={<RequiredAuth> <AllFreeClass/> </RequiredAuth>} />
          <Route path='allOtherVideos/:clusterId' element={<RequiredAuth> <AllOtherVideos/> </RequiredAuth>} />
          <Route path='content' element={<RequiredAuth> <Content/> </RequiredAuth>} />
          <Route path='contentList/:clusterGroupMappingId/:courseMappingId/:topicMapId' element={<RequiredAuth> <ContentList/> </RequiredAuth>} />
          <Route path='contentList/:courseMappingId' element={<RequiredAuth> <ContentList/> </RequiredAuth>} />
          <Route path='course/:clusterGroupMappingId/:courseMappingId' element={<RequiredAuth> <Course/> </RequiredAuth>} />
          <Route path='course/:courseMappingId' element={<RequiredAuth> <Course/> </RequiredAuth>} />
          <Route path='coachmi/clusterGroupCourse/:clusterGroupMappingId' element={<RequiredAuth> <ClusterGroupCourse/> </RequiredAuth>} />
          {/* <Route path='contentList/:courseMappingId/:classMapingId' element={<RequiredAuth> <ContentList/> </RequiredAuth>} /> */}
          <Route path='subscriptionPlan/:id' element={<RequiredAuth> <SubscriptionPlan/> </RequiredAuth>} />
          <Route path='coachmiTestSeries/:clusterId' element={<RequiredAuth> <CoachmiTestSeries/> </RequiredAuth>} />
          <Route path='allEducators/:clusterId' element={<RequiredAuth> <AllEducators/> </RequiredAuth>} />
          <Route path='allClusterGroups/:clusterId' element={<RequiredAuth> <AllClusterGroup/> </RequiredAuth>} />


          <Route path='' element={<RequiredAuth><Home /></RequiredAuth>} />
        </Route>
        <Route path='*' element={<NoMatchRoute />} />
        <Route path='/' element={<NoMatchRoute />} />
      </Routes>
    </>
  );
}

export default App;
