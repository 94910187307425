import { Divider, Image } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOrderList ,isPurchasedTestLoading } from '../Slices/PurchasedListSlice'
import headerImage from '../assets/images/orderHeader.svg'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import Loading from '../Component/Loading'
import BreadCrumbDetails from '../Component/BreadCrumb'
import { addBreadCrumbData } from '../Slices/HomePageSlice'

const SingleOrder = () => {
 const dispatch = useDispatch()
 const params = useParams()
 const { orderList,isPurchasedTestLoading } = useSelector(((state) => state.PurchasedList))
 const singleList = orderList?.filter((data, index) => data.paymentId == params.id)
 const singleData = singleList[0]
 useEffect(() => {
  dispatch(getOrderList())
  dispatch(addBreadCrumbData({isOrder:true ,isOrderHistory:true}))

 }, [])

 return (
  <>
{
  orderList.length ?
  <div className='singleOrder-container'>
        <div className='breadCrumbs-container'>
      <BreadCrumbDetails></BreadCrumbDetails>
    </div>
   <div className='header-container'>

    <div className='header-content'>
     <div className='details'>
      {/* <p className='orderText'>Order ID - data.purchaseId</p> */}
      <p className='orderMessage'>Rs. {singleData?.amountPaid / 100}, {singleData.paymentStatus == 'COMPLETED' ? <span className='success'>Your order is successful</span> : <span className='failed'>Your order is unsuccess</span>}</p>
      <p className='totalItems'>{singleData.testData.length} items</p>
      <p className='orderTime'> {moment(singleData.paymentDate).format('DD MMM YYYY, hh:mm A')}</p>
     </div>
     <Image preview={false} src={headerImage}></Image>
    </div>
   </div>
   <div className='body-container'>
    <div className='body-wrapper'>
     <h2 className='subHeader'>Items</h2>
     {
      singleData?.testData.map((data,index)=>{
       return (
        <div className='body-content' key={index}>
      <div className='content-details'>
       <div className='details'>
        <p> 
        {
         data.testTypeId == 1 ?
        <span className='fullTest' >Full Syllabus</span>
         : data.testTypeId == 2 ?
         <span className='subTest'>Subject Test</span>
         : data.testTypeId == 3 ?
         <span className='chapTest'>Chapter Test</span>
         :null
        }
        <Divider style={{background:'#7A8B94',marginTop:2}} type='vertical'></Divider>

         <span className='examName'>{data.examName}</span> </p>
        <p className='testName'>{data.testName}</p>
       </div>
       <p className='total'>₹ {data.testPrice}</p>
      </div>
      <div className='sub-total'>
       <span className='sub-text'>
        Subtotal ({data.noOfTest})
        <span className='sub-amount'>
         ₹ {data.totalPrice}
        </span>
       </span>

      </div>

     </div>
       )
      })
     }
 
    </div>
   </div>
  </div>
  :null

}
{
  isPurchasedTestLoading ?
  <Loading></Loading>
  :null
}

  </>
 )
}

export default SingleOrder