import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getEducatorbyClusterId, educatorFollowStatus } from '../Slices/CoachmiSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as TamilIcon } from '../assets/images/tamilIcon.svg';
import { ReactComponent as EnglishIcon } from '../assets/images/englishIcon.svg';
import { Image } from "antd";
import BackIcon from '../assets/images/back_icon.svg';
import '../styles/AllEducators.scss';
import { renderFollowStatusFunction } from "../utlis/HelperFunctions.js";
import Loading from '../Component/Loading';



const AllEducators = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clusterId } = useParams();
  const { educatorClusterData, educatorFollowData,isEducatorLoading,isLoading } = useSelector((state) => state.Coachmi);

  useEffect(() => {
    dispatch(getEducatorbyClusterId({ clusterId: clusterId, isLimitedData: false }))
  }, [])

  const goBack = () => {
    navigate('/home/coachmi')
  }

  const handleFollow = (id) => {
        dispatch(educatorFollowStatus({ id, clusterId, isLimitedData:false }))
  };


  return (
    <>
     {
            isLoading ? (
                <div className='loading-container'>
                <Loading></Loading>
                </div>
            ) : (
      <div className="overAll-course-contaniner">
        <div className='course-header'>
          <Image preview={false} src={BackIcon} className="back-icon" onClick={() => goBack()} />
          <p>Educators</p>
        </div>

        <div className="free-classes-container">
          <div className="educators">
            {educatorClusterData?.map((educator, index) => (
              <div className="educator-card" key={index}>
                <img
                  src={educator.attachmentUrl}
                  alt={educator.educatorName}
                  className="educator-image"
                />
                <div className="educator-text">
                  <span className="educator-name">{educator.educatorName}</span>
                  <span className="educator-follow">
                    {educator.followingCount} followers
                  </span>
                  {renderFollowStatusFunction(educator, handleFollow, isEducatorLoading)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
    </>
  );
}

export default AllEducators;
