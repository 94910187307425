import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { message } from "antd";


export const getMyPurchasedList = createAsyncThunk(
  "PurchasedList/getMyPurchasedList", async () => {
    try {
      let purchasedData = await axios.get('test/getPurchaseTest')
        .then((res) => {
          if (res.data.status) {
            return res.data.data
            // let arr = []
            // return arr
          }
        })
      return purchasedData
    }
    catch (error) {
    }
  }
)

export const getAttemptedTestByTestMasterStudentMapId = createAsyncThunk(
  "AttendTest/getAttemptedTestByTestMasterStudentMapId", async (payload) => {
    try {
      let data = await axios.get('test/viewSolution/' + payload)
        .then((res) => {
          if (res.data.status) {
            return res.data
          }
        })
      return data
    }
    catch (error) {
    }
  }
)

/* view solution institution student */
export const getAttTestByTestMasterInsStudMapId = createAsyncThunk(
  "AttendTest/getAttTestByTestMasterInsStudMapId", async (payload,thunkAPI) => {
    try {
      let data = await axios.get('test/viewSolutionInsStudent/' + payload)
        .then((res) => {
          if (res.data.status) {
            return res.data
          }
        })
      return data
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)


/* download question and answer */
export const studentResultDownload = createAsyncThunk(
  "AttendTest/getAttTestByTestMasterInsStudMapId", async (payload) => {
    
    try {
      let data = await axios.get('test/downloadSolutionInsStudent/' + payload)
        .then((res) => {
          if (res.data.status) {
            return res.data
          }
        })
      return data
    }
    catch (error) {
    }
  }
)


export const getOrderList = createAsyncThunk("OrderPage/getorders", async (payload) => {
  try {
    let data = await axios.get("/test/getOrderTest").then((res) => {
      if (res.data.status) {
        return res.data.data
      }
    })
    return data
  } catch (error) {
  }
})

export const GetAllCoupons = createAsyncThunk(
  "Coupon/getAllCoupons", async () => {
    try {
      let getAllCoupons = await axios.get('test/getCouponDetailById')
        .then((res) => {
          if (res.data.status) {
            return res.data.data
            // let arr = []
            // return arr
          }
        })
      return getAllCoupons
    }
    catch (error) {
    }
  }
)


export const proceedToPayWithCoupon = createAsyncThunk(
  'cart/proceedToPay', async (payload) => {
    try {
      const payOrderData = await axios.post(`test/proceedToPay`, payload)
      return payOrderData['data']

    } catch (error) {
    }
  }
)


export const couponApplyCart = createAsyncThunk(
  'cart/couponApply', async (payload) => {
    try {
      const payOrderData = await axios.put(`test/couponApply/${payload}`, {})
        .then((res) => {
          if (res.data.status) {

            return res.data.data
          }
        })
      return payOrderData
    } catch (error) {
    }
  }
)

export const deleteCoupon = createAsyncThunk(
  'cart/deleteCoupon', async (params) => {
    try {
      const deleteCouponData = await axios.put(`test/deleteCoupon/${params}`, {})
      return { data: deleteCouponData['data'] }
    } catch (error) {
      // return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const getAttemptedTestByPreviousYearExam = createAsyncThunk(
  "AttendTest/getAttemptedTestByPreviousYearExam", async (payload) => {
    try {
      let data = await axios.get('test/viewSolutionPreviousYearExam/' + payload)
        .then((res) => {
          if (res.data.status) {
            return res.data
          }
        })
      return data
    }
    catch (error) {
    }
  }
)


const initialState = {
  isPurchasedTestLoading: false,
  myPurchasedList: [],
  purchasedCount: [],
  attendQuestionList: [],
  attendTestDetails: {},
  orderList: [],
  getAllCoupons: [],
  proceedToPayData: {},
  couponApplyCartData: {}

}


const PurchasedList = createSlice({
  name: 'PurchasedList',
  initialState,
  extraReducers: {
    [getMyPurchasedList.pending]: (state) => {
      state.isPurchasedTestLoading = true
    },
    [getMyPurchasedList.fulfilled]: (state, actions) => {
      if (actions.payload.length != 0) {
        // let all={
        //   testType:'All',
        //   id:-1,
        //   numberOfTest:actions.payload.getPurchaseTest.length
        // }
        state.myPurchasedList = actions.payload?.getPurchaseTest
        state.purchasedCount = actions.payload?.typeOfTest
        // state.purchasedCount.unshift(all)
      }
      state.isPurchasedTestLoading = false
    },
    [getMyPurchasedList.rejected]: (state) => {
      state.isPurchasedTestLoading = false
    },
    [getAttemptedTestByTestMasterStudentMapId.pending]: (state, actions) => {
      state.isPurchasedTestLoading = true
    },
    [getAttemptedTestByTestMasterStudentMapId.fulfilled]: (state, actions) => {
      state.isPurchasedTestLoading = false
      state.attendQuestionList = actions.payload.questionData
      state.attendTestDetails = actions.payload.testDetails
    },
    [getAttemptedTestByTestMasterStudentMapId.rejected]: (state, actions) => {
      state.isPurchasedTestLoading = false
    },
    /* institution student solution mapping */
    [getAttTestByTestMasterInsStudMapId.pending]: (state, actions) => {
      state.isPurchasedTestLoading = true
    },
    [getAttTestByTestMasterInsStudMapId.fulfilled]: (state, actions) => {
      state.isPurchasedTestLoading = false
      state.attendQuestionList = actions.payload.questionData
      state.attendTestDetails = actions.payload.testDetails
    },
    [getAttTestByTestMasterInsStudMapId.rejected]: (state, actions) => {
      state.isPurchasedTestLoading = false
    },
    [studentResultDownload.pending]: (state, actions) => {
      state.isPurchasedTestLoading = true
    },
    [studentResultDownload.fulfilled]: (state, actions) => {
      state.isPurchasedTestLoading = false
      state.attendQuestionList = actions.payload.questionData
      state.attendTestDetails = actions.payload.testDetails
    },
    [studentResultDownload.rejected]: (state, actions) => {
      state.isPurchasedTestLoading = false
    },
    [getOrderList.pending]: (state, actions) => {
      state.isPurchasedTestLoading = true
    },
    [getOrderList.fulfilled]: (state, actions) => {
      state.isPurchasedTestLoading = false
      state.orderList = actions.payload
    },
    [getOrderList.rejected]: (state, actions) => {
      state.isPurchasedTestLoading = false
    },
    [GetAllCoupons.pending]: (state, actions) => {
      state.isPurchasedTestLoading = true
    },
    [GetAllCoupons.fulfilled]: (state, actions) => {
      state.isPurchasedTestLoading = false
      state.getAllCoupons = actions.payload
    },
    [GetAllCoupons.rejected]: (state, actions) => {
      state.isPurchasedTestLoading = false
    },
    [couponApplyCart.pending]: (state, actions) => {
      state.isPurchasedTestLoading = true
    },
    [couponApplyCart.fulfilled]: (state, actions) => {
      state.isPurchasedTestLoading = false
      state.couponApplyCartData = actions.payload
    },
    [couponApplyCart.rejected]: (state, actions) => {
      state.isPurchasedTestLoading = false
    },
    [proceedToPayWithCoupon.pending]: (state, actions) => {
      state.isPurchasedTestLoading = true
    },
    [proceedToPayWithCoupon.fulfilled]: (state, actions) => {
      state.isPurchasedTestLoading = false
      state.proceedToPayData = actions.payload
    },
    [proceedToPayWithCoupon.rejected]: (state, actions) => {
      state.isPurchasedTestLoading = false
    },
    [deleteCoupon.pending]: (state, actions) => {
      state.isPurchasedTestLoading = true
    },
    [deleteCoupon.fulfilled]: (state, actions) => {
      state.isPurchasedTestLoading = false
      state.couponApplyCartData = {}
    },
    [deleteCoupon.rejected]: (state, actions) => {
      state.isPurchasedTestLoading = false
    },
    [getAttemptedTestByPreviousYearExam.pending]: (state, actions) => {
      state.isPurchasedTestLoading = true
    },
    [getAttemptedTestByPreviousYearExam.fulfilled]: (state, actions) => {
      state.isPurchasedTestLoading = false
      state.attendQuestionList = actions.payload.questionData
      state.attendTestDetails = actions.payload.testDetails
    },
    [getAttemptedTestByPreviousYearExam.rejected]: (state, actions) => {
      state.isPurchasedTestLoading = false
    },
  }
})

export default PurchasedList.reducer;