import { message as Toast } from 'antd';
import ComponentLoading from '../Component/ComponentLoading';

export const getLeastMonthsSubscriptionHelper = (subscriptions) => {
    if (!subscriptions || subscriptions?.length === 0) {
        return null;
    }
    return subscriptions?.reduce((min, current) => {
        return current.numberOfMonths < min.numberOfMonths ? current : min;
    });
};

export const PricingNoDataContentHelper = () => {
    return (
        <p>No Plan Pricing Data</p>
    );
};

export const setLocalStorageItemHelper = (key, value) => {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
    }
};

export const getLocalStorageItemHelper = (key) => {
    try {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    } catch (error) {
        return null;
    }
};

export const handleToastHelper = ({ success, message }, errorToastOnly = false) => {
    if (!success) {
        return Toast.error(message);
    };

    if (!errorToastOnly) {
        return Toast.success(message);
    };
};

export const renderFollowStatusFunction = (educator, paramFunction, isLoading) => {
    const className = educator?.following ? 'follow following' : 'follow';
    const content = educator?.following ? 'Following' : 'Follow';
    return (
        <span className={className}
            onClick={() => paramFunction(educator?.id)}
        >
            {isLoading ? <ComponentLoading /> : content}</span>
    )
};

export const renderVerticalLineHelper = (condition) => {
    return condition && <p className="vertical-line"></p>;
};
export const CoursePricingHelper = (course) => {
    return (
        <div className="course-pricing">
            {
                course['coursePrice'] && (
                    <span className="price">₹ {course['coursePrice']}</span>
                )
            }

            {
                course['actualPrice'] &&
                course['coursePrice'] &&
                course['coursePrice'] !== course['actualPrice'] && (
                    <span className="price original-price">₹ {course['actualPrice']}</span>
                )
            }
        </div>
    );
};
