import React, { useState, useEffect } from 'react'
import { Button, Image, Divider } from 'antd'
import Language from '../assets/images/languages.svg';
import '../styles/attemptedTest.scss';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { AllBookMarkAnswerAndUnAnswered, getSubmitQuestion, startLoader, stopLoader, getSubmitQuestionPreviousYearExam, AllBookMarkPreviousTest } from '../Slices/TestPageSlice';
import { startTakeTest } from '../Slices/TestListSlice';
import { getAttendedTest } from '../Slices/attendedSlice';
import { getTestSolutionPreviousYearExam } from '../Slices/attendedSlice';


function AttemptedTestComponent({ item }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isLoadingTestPage } = useSelector((state) => state.TestMaster)

  const continueTest = (id) => {
    dispatch(startTakeTest())
    dispatch(startLoader())
    dispatch(getSubmitQuestion(id)).unwrap().then((res) => {
      if (res) {
        dispatch(AllBookMarkAnswerAndUnAnswered(id)).unwrap().then((res) => {
          dispatch(stopLoader())
          if (res.length) {
            navigate('/testPage/' + id)
          }
        })
      } else {
        dispatch(stopLoader())
        dispatch(getAttendedTest()).unwrap().then((res) => {
        })
      }
    })
  }

  const continueTestQuestionBank = (id) => {
    dispatch(startTakeTest())
    dispatch(startLoader())
    dispatch(getSubmitQuestionPreviousYearExam(id)).unwrap().then((res) => {
      if (res) {
        dispatch(AllBookMarkPreviousTest(id)).unwrap().then((res) => {
          dispatch(stopLoader())
          if (res.length) {
            navigate('/testPagePreviosYearExam/' + id)
          }
        })
      } else {
        dispatch(stopLoader())
        dispatch(getTestSolutionPreviousYearExam()).unwrap().then((res) => {
        })
      }
    })
  }
  return (
    <div className='attempted-test-component'>
      <div className='attempted-test-content'>
        <p className="exam-title"><span className={item.typeOfTestId == 1 ? 'full-test-title' : (item.typeOfTestId == 2 ? 'subject-title' : 'chapter-title')}>{item.typeOfTestName}</span> <Divider style={{ background: '#7A8B94' }} type='vertical'></Divider><span> {item.examName} </span></p>
        <p className='attempted-test-component-test'>{item.testName}</p>
        <div className='attempted-test-component-questions-solutions'>
          <p className='attempted-test-component-marks-solutions'>{item.markObtained ? item.markObtained : 0}/{item.totalMark} marks</p>
          <Divider style={{ background: '#7A8B94', marginTop: 2 }} type='vertical'></Divider>
          <p className='attempted-test-component-date-solutions'>{moment(item.createdAt).format('DD MMM YY')}</p>
        </div>
      </div>
      {
        +item.typeOfTestId != 4 ? <div className='view-solutions'>
          {
            item.isCompleted ?
              <Button onClick={() => navigate('/home/solutions/' + item.testMasterStudentMapId)}>View Result</Button>
              :
              <Button onClick={() => continueTest(item.testMasterStudentMapId)} >Continue Test</Button>
          }
        </div> :
          <div className='view-solutions'>
            {
              item.isCompleted ?
                <Button onClick={() => navigate('/home/solutionsPreviousYearExam/' + item.testMasterStudentMapId)}>View Result</Button>
                :
                <Button onClick={() => continueTestQuestionBank(item.testMasterStudentMapId)} >Continue Test</Button>
            }
          </div>
      }
    </div>
  )
}

export default AttemptedTestComponent;