import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getActiveCluster = createAsyncThunk(
  "Coachmi/getActiveCluster", async (payload) => {
    try {
      let clusterData = await axios.get('coachmi/getCluster')
      return clusterData['data'] || []
    }
    catch (error) {

    }
  }
);

export const getAllCoursesByClusterGroup = createAsyncThunk(
  'coachmi/getAllCoursesByClusterGroup', async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(`coachmi/getAllCoursesByClusterGroupId/${payload.id}`);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);
      return rejectWithValue(error.message);
    }
  });

export const getCourse = createAsyncThunk(
  'coachmi/getCourse', async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(`coachmi/getAllCourseDetailsById/${payload.id}`);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);
      return rejectWithValue(error.message);
    }
  });

export const getFreeClass = createAsyncThunk(
  'coachmi/getFreeClass', async (payload, { rejectWithValue }) => {
    try {
      // const response = await axios.get(`coachmi/getFreeClass/${payload.id}`);

      const freeClasses = [
        {
          id: 1,
          image: 'https://centumapp.s3.amazonaws.com/public/Image1716382003394_Screenshot%20%288%29.png',
          freeClassName: 'Human Development Indicators in Tamil Nadu',
          freeClassDate: 'Feb 7',
          freeClassTime: '4:04 PM',
          educator: 'Eswaran',
        },
        {
          id: 2,
          image: 'https://centumapp.s3.ap-south-1.amazonaws.com/public/Image1717154964520_Screenshot%202024-05-31%20104158.png',
          freeClassName: 'Human Development Indicators in Tamil Nadu',
          freeClassDate: 'Feb 7',
          freeClassTime: '4:04 PM',
          educator: 'Eswaran',
        },
        {
          id: 3,
          image: 'https://centumapp.s3.amazonaws.com/public/Image1716382003394_Screenshot%20%288%29.png',
          freeClassName: 'Human Development Indicators in Tamil Nadu',
          freeClassDate: 'Feb 7',
          freeClassTime: '4:04 PM',
          educator: 'Eswaran',
        },
      ];
      return freeClasses;
      // return response.data;
    } catch (error) {
      console.error("Error:", error.message);
      return rejectWithValue(error.message);
    }
  });

export const getContentCount = createAsyncThunk(
  'coachmi/getContentCount', async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(`coachmi/getCourseDetails/${payload.id}`);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);
      return rejectWithValue(error.message);
    }
  });

export const getContentByClassId = createAsyncThunk(
  'coachmi/getContentByClassId', async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(`coachmi/getAllContentByClassId/${payload}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

export const getContentFilter = createAsyncThunk(
  'coachmi/getContentFilter', async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(`coachmi/getAllCourseMapTopicById/${payload.id}`);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);
      return rejectWithValue(error.message);
    }
  });

export const getSubscriptionPlan = createAsyncThunk(
  'coachmi/getSubscriptionPlan', async ({ type, id, queryParams }, { rejectWithValue }) => {
    try {
      const queryString = queryParams ? new URLSearchParams(queryParams).toString() : '';
      const url = `coachmi/getCoachmiSubscription/${type}/${id}${queryString ? `?${queryString}` : ''}`;
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

export const addCoachmiCart = createAsyncThunk(
  'cart/addCoachmiCart', async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(`cart/addCoachmiCart`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error['response']['data']);
    };
  });

export const getAllCourseByClusterId = createAsyncThunk(
  "Coachmi/getAllCourseByClusterId", async ({ clusterId, isLimitedData }) => {
    try {
      let course = await axios.get(`coachmi/getAllCourses/${clusterId}?isLimitedData=${isLimitedData}`)
      return course['data'] || []
    }
    catch (error) {

    }
  }
);

export const getFreeClassesByClusterId = createAsyncThunk(
  "Coachmi/getFreeClassesByClusterId",
  async ({clusterId,isLimitedData}) => {
    try {
      let freeClass = await axios.get(`coachmi/getFreeClassVideo/${clusterId}?isLimitedData=${isLimitedData}`)
      return freeClass['data'] || []
    } catch (error) {

    }
  }
);

export const getAllTestSeriesByClusterId = createAsyncThunk(
  "Coachmi/getAllTestSeriesByClusterId", async ({ clusterId, isLimitedData }) => {
    try {
      let test = await axios.get(`coachmi/getAllTestSeries/${clusterId}?isLimitedData=${isLimitedData}`)
      return test['data'] || []
    }
    catch (error) {

    }
  }
);

export const getAllOtherVideosByClusterId = createAsyncThunk(
  "Coachmi/getAllOtherVideosByClusterId", async ({clusterId,isLimitedData}) => {
    try {
      let otherVideos = await axios.get(`coachmi/getAllOtherVideos/${clusterId}?isLimitedData=${isLimitedData}`)
      return otherVideos['data'] || []
    }
    catch (error) {

    }
  }
);

export const getContentDetails = createAsyncThunk(
  "Coachmi/getContentDetails", async (contentMappingId) => {
    try {
      let content = await axios.get(`coachmi/getContentDetails/${contentMappingId}`)
      return content['data'] || []
    }
    catch (error) {

    }
  }
);

export const getClusterGroupDetailsById = createAsyncThunk(
  "Coachmi/getClusterGroupDetailsById", async (payload) => {
    try {
      let clusterGroup = await axios.get(`coachmi/getClusterGroupDetailsById/${payload.id}`)
      return clusterGroup['data'] || []
    }
    catch (error) {

    }
  }
);

export const getTopicDetailsById = createAsyncThunk(
  "Coachmi/getTopicDetailsById", async (payload) => {
    try {
      let topic = await axios.get(`coachmi/getTopicDetailsById/${payload.id}`)
      return topic['data'] || []
    }
    catch (error) {

    }
  }
);

export const getStudyMaterialsById = createAsyncThunk(
  "Coachmi/getStudyMaterialsById", async ({ contentMapId }) => {
    try {
      let studyMaterial = await axios.get(`coachmi/getContentAttachment/${contentMapId}`)
      return studyMaterial['data'] || []
    }
    catch (error) {

    }
  }
);


export const getAllRecommendedTest = createAsyncThunk(
  "Coachmi/getAllRecommendedTest", async ({ id }) => {
    try {
      let recommendedTest = await axios.get(`coachmi/getAllRecommendedTest/${id}`)
      return recommendedTest['data'] || []
    }
    catch (error) {

    }
  }
);

export const getContentEducator = createAsyncThunk(
  "Coachmi/getContentEducator", async ( contentMapId ) => {
    try {
      let educator = await axios.get(`coachmi/getContentEducator/${contentMapId}`)
      return educator['data'] || []
    }
    catch (error) {

    }
  }
);

export const getEducatorbyClusterId = createAsyncThunk(
  "Coachmi/getEducatorbyClusterId", async ({ clusterId, isLimitedData }) => {
    try {
      let educator = await axios.get(`coachmi/getEducatorbyClusterId/${clusterId}?isLimitedData=${isLimitedData}`)
      return educator['data'] || []
    }
    catch (error) {
    }
  }
);


export const educatorFollowStatus = createAsyncThunk(
  "Coachmi/educatorFollowStatus",
  async ({ id, clusterId, isLimitedData = true }, thunk) => {
    try {
      const response = await axios.post(`coachmi/educatorFollowStatus/${id}`);

      if (response.data.success) {
        await thunk.dispatch(getEducatorbyClusterId({ clusterId, isLimitedData }));
      }
      return response['data'] || [];
    } catch (error) {
      throw error;
    }
  }
);


export const educatorContentFollow = createAsyncThunk(
  "Coachmi/educatorFollowStatus",
  async ({ id, contentMappingId }, thunk) => {
    try {
      const response = await axios.post(`coachmi/educatorFollowStatus/${id}`);

      if (response.data.success) {
        await thunk.dispatch(getContentEducator(contentMappingId));
      }
      return response['data'] || [];
    } catch (error) {
      throw error;
    }
  }
);


export const getCoachmiBanner = createAsyncThunk(
  "Coachmi/getCoachmiBanner", async () => {
    try {
      let banner = await axios.get(`test/getMockTestBanner/`)
      return banner['data'] || []
    }
    catch (error) {
    }
  }
)

export const getAllClusterGroupByClusterId = createAsyncThunk(
  "Coachmi/getAllClusterGroupByClusterId", async ({ clusterId, isLimitedData }) => {
    try {
      let clusterGroup = await axios.get(`coachmi/getAllClusterGroupByClusterId/${clusterId}?isLimitedData=${isLimitedData}`)
      return clusterGroup['data'] || []
    }
    catch (error) {

    }
  }
);

export const getContentVideoUrl = createAsyncThunk(
  "Coachmi/getContentVideoUrl", async (payload) => {
    try {
      let videoUrl = await axios.get(`coachmi/getCloudFrontUrlById/${payload.type}/${payload.contentAttachmentId}`)
      return videoUrl['data'] || []
    }
    catch (error) {

    }
  }
);

export const getContentFilterbyTopic = createAsyncThunk(
  'coachmi/getContentFilterbyTopic', async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(`coachmi/getAllContentByTopic/${payload}`);
      return response['data'] || [];
    } catch (error) {
      console.error("Error:", error.message);
      return rejectWithValue(error.message);
    }
  });


export const getContentFilterbySubTopic = createAsyncThunk(
  'coachmi/getContentFilterbySubTopic', async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(`coachmi/getAllContentBySubTopic/${payload}`);
      return response['data'] || [];
    } catch (error) {
      console.error("Error:", error.message);
      return rejectWithValue(error.message);
    }
  });

export const getSearchValue = createAsyncThunk(
  "Coachmi/getSearchValue", async (payload) => {
    try {
      let searchValue = await axios.get(`coachmi/getSearchValue/${payload.clusterId}?searchValue=${payload.searchValue}`)
      return searchValue['data'] || []
    }
    catch (error) {

    }
  }
);

let initialState = {
  isLoading: false,
  clusterData: [],
  subscriptionData: {},
  clusterGroupData: [],
  allCourseData: [],
  educatorsData: [],
  freeClassData: [],
  testData: [],
  otherVideosData: [],
  contentData: {},
  testData: [],
  studyMaterialsData: [],
  educatorData: {},
  pricePlanData: {},
  ClusterGroupCourseData: [],
  CourseData: {},
  FreeClassData: [],
  contentCountData: [],
  ContentByClassData: [],
  ContentFilterData: [],
  clusterGroupDetails: {},
  topicDetails: {},
  FreeClassVideoData: [],
  educatorClusterData: [],
  educatorFollowData: [],
  recommendedTestData: [],
  contentEducatorsData: [],
  bannerData: [],
  contentVideo: {},
  isLoadingCluster: false,
  educatorContentData: [],
  isContentLoading: false,
  isEducatorLoading: false,
  isCourseLoading:false,
};

const Coachmi = createSlice({
  name: 'Coachmi',
  initialState,
  reducers: {
    removesubscription(state) {
      state['subscriptionData'] = {};
    },
  },
  extraReducers: {
    [getActiveCluster.pending]: (state) => {
      state.isLoadingCluster = true;
    },
    [getActiveCluster.fulfilled]: (state, actions) => {
      state.isLoadingCluster = false;
      state.clusterData = actions.payload.data;
      state.defaultClusterId = actions.payload.data[0]?.id;
    },
    [getActiveCluster.rejected]: (state) => {
      state.isLoadingCluster = false;
    },
    [getEducatorbyClusterId.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getEducatorbyClusterId.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.educatorClusterData = action.payload.data;
    },
    [getEducatorbyClusterId.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [educatorFollowStatus.pending]: (state, action) => {
      state.isEducatorLoading = true;
    },
    [educatorFollowStatus.fulfilled]: (state, action) => {
      state.isEducatorLoading = false;
      state.educatorFollowData = action.payload.data;
    },
    [educatorFollowStatus.rejected]: (state, action) => {
      state.isEducatorLoading = false;
    },
    [educatorContentFollow.pending]: (state, action) => {
      state.isLoading = true;
    },
    [educatorContentFollow.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.contentEducatorsData = action.payload.data;
    },
    [educatorContentFollow.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getSubscriptionPlan.pending]: (state) => {
      state.isLoading = true;
    },
    [getSubscriptionPlan.fulfilled]: (state, actions) => {
      state.isLoading = false;
      state.subscriptionData = actions.payload?.data;
    },
    [getSubscriptionPlan.rejected]: (state) => {
      state.isLoading = false;
    },
    [getAllCourseByClusterId.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllCourseByClusterId.fulfilled]: (state, actions) => {
      state.isLoading = false;
      state.allCourseData = actions.payload.data;
    },
    [getAllCourseByClusterId.rejected]: (state) => {
      state.isLoading = false;
    },
    [getFreeClassesByClusterId.pending]: (state) => {
      state.isLoading = true;
    },
    [getFreeClassesByClusterId.fulfilled]: (state, actions) => {
      state.isLoading = false;
      state.freeClassData = actions.payload.data;

    },
    [getFreeClassesByClusterId.rejected]: (state) => {
      state.isLoading = false;
    },
    [getAllTestSeriesByClusterId.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllTestSeriesByClusterId.fulfilled]: (state, actions) => {
      state.isLoading = false;
      state.testData= actions.payload.data;

    },
    [getAllTestSeriesByClusterId.rejected]: (state) => {
      state.isLoading = false;
    },
    [getAllOtherVideosByClusterId.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllOtherVideosByClusterId.fulfilled]: (state, actions) => {
      state.isLoading = false;
      state.otherVideosData = actions.payload.data;
    },
    [getAllOtherVideosByClusterId.rejected]: (state) => {
      state.isLoading = false;
    },
    [getContentDetails.pending]: (state) => {
      state.isLoading = true;
    },
    [getContentDetails.fulfilled]: (state, actions) => {
      state.isLoading = false;
      state.contentData = actions.payload.data[0]
    },
    [getContentDetails.rejected]: (state) => {
      state.isLoading = false;
    },
    [getAllCoursesByClusterGroup.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllCoursesByClusterGroup.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.ClusterGroupCourseData = action.payload.data;
    },
    [getAllCoursesByClusterGroup.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getCourse.pending]: (state) => {
      state.isCourseLoading = true;
    },
    [getCourse.fulfilled]: (state, action) => {
      state.isCourseLoading = false;
      state.CourseData = action.payload.data[0];
    },
    [getCourse.rejected]: (state, action) => {
      state.isCourseLoading = false;

    },
    [getFreeClass.pending]: (state) => {
      state.isLoading = true;
    },
    [getFreeClass.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.FreeClassData = action.payload;
    },
    [getFreeClass.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getContentCount.pending]: (state) => {
      state.isLoading = true;
    },
    [getContentCount.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.contentCountData = action.payload.data;
    },
    [getContentCount.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getContentByClassId.pending]: (state) => {
      state.isContentLoading = true;
    },
    [getContentByClassId.fulfilled]: (state, action) => {
      state.isContentLoading = false;
      state.ContentByClassData = action.payload.data;
    },
    [getContentByClassId.rejected]: (state, action) => {
      state.isContentLoading = false;
    },
    [getContentFilter.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getContentFilter.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.ContentFilterData = action.payload.data;
    },
    [getContentFilter.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getClusterGroupDetailsById.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getClusterGroupDetailsById.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.clusterGroupDetails = action.payload?.data?.[0]?.result
    },
    [getClusterGroupDetailsById.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getTopicDetailsById.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getTopicDetailsById.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.topicDetails = action.payload.data[0].result
    },
    [getTopicDetailsById.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getStudyMaterialsById.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getStudyMaterialsById.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.studyMaterialsData = action.payload.data
    },
    [getStudyMaterialsById.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [getAllRecommendedTest.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getAllRecommendedTest.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.recommendedTestData = action.payload.data
    },
    [getAllRecommendedTest.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getContentEducator.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getContentEducator.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.contentEducatorsData = action.payload.data;

    },
    [getContentEducator.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getCoachmiBanner.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getCoachmiBanner.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.bannerData = action.payload.data
    },
    [getCoachmiBanner.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getAllClusterGroupByClusterId.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getAllClusterGroupByClusterId.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.clusterGroupData = action?.payload?.data
    },
    [getAllClusterGroupByClusterId.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getContentVideoUrl.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getContentVideoUrl.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.contentVideo = action?.payload?.data
    },
    [getContentVideoUrl.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [addCoachmiCart.pending]: (state, action) => {
      state.isLoading = true;
    },
    [addCoachmiCart.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [addCoachmiCart.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getContentFilterbyTopic.pending]: (state, action) => {
      state.isContentLoading = true;
    },
    [getContentFilterbyTopic.fulfilled]: (state, action) => {
      state.isContentLoading = false;
      state.ContentByClassData = action.payload.data;
    },
    [getContentFilterbyTopic.rejected]: (state, action) => {
      state.isContentLoading = false;
    },
    [getContentFilterbySubTopic.pending]: (state, action) => {
      state.isContentLoading = true;
    },
    [getContentFilterbySubTopic.fulfilled]: (state, action) => {
      state.isContentLoading = false;
      state.ContentByClassData = action.payload.data;
    },
    [getContentFilterbySubTopic.rejected]: (state, action) => {
      state.isContentLoading = false;
    },
    [getSearchValue.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getSearchValue.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [getSearchValue.rejected]: (state, action) => {
      state.isLoading = false;
    },

  },
});

export const { removesubscription } = Coachmi.actions;

export default Coachmi.reducer;